import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FloatingPanel from '../Panel/FloatingPanel';

import styles from './formFlot-jss';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ls from 'local-storage';

class AddCatalogue extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: [],
      title: this.props.catalogue ? ls.get("catalogue_list").find(e => e.id_catalogue == this.props.catalogue).cat_name : this.props.title
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    let oldFiles = this.state.files;
    const filesLimit = 2;
    oldFiles = oldFiles.concat(files);
    if (oldFiles.length > filesLimit) {
      console.log('Cannot upload more than ' + filesLimit + ' items.');
    } else {
      this.setState({ img: files[0].preview || '/pic' + files[0].path });
    }
  }

  sendValues = (values) => {
    setTimeout(() => {
      this.props.submit(values, this.state.img);
      this.setState({ img: '' });
    }, 500);
  }

  //Life cycle
  componentDidMount (){
    //AHDA 29/04/2019
    //Validation for Cell
    ValidatorForm.addValidationRule('isCell', (value) => {
      if(value){
        if (value.length === 10 || value.length === 13  || value.length === 0) {
            return true;
        }
        return false;
      }else{
        return true; 
      }
    });

    //AHDA 03/06/2019
    //Empty strings validation
    ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
      if (/^\s+$/i.test(value)) {
          return false;
      }
      return true;
    });

    //AHDA 04/06/2019
    //Simple quote validation 
    ValidatorForm.addValidationRule('hasQuote', (value) => {
      // JCMY 04/07/2019
      if(value != null && value.indexOf('\'') <= -1){
        return true;
      }else{
        if(value == null){
          return true; 
        }else{
          return false; 
        }

      }
    });
    setTimeout(()=>{
      this.setState({title: this.props.catalogue ? ls.get("catalogue_list").find(e => e.id_catalogue == this.props.catalogue).cat_name : this.props.title})
    },2000) 
  }

  render() {
    const {
      classes,
      openForm,
      closeForm,
      submit, 
      clearForm, 
      withForm
    } = this.props;

    const branch = '';
    return (
        <FloatingPanel title={this.state.title} openForm={openForm} branch={branch} closeForm={closeForm}>

        {
          withForm ? 
          <ValidatorForm onSubmit={submit}>
            <section className={classes.bodyFormFlot}>
            
              {this.props.children}
              
            </section>
            <div className={classes.buttonArea}>
              <Button variant="contained" color="primary" type="submit" >Guardar</Button>
              <Button type="button" onClick={clearForm}>Limpiar</Button>
            </div>
          </ValidatorForm> : 
          <React.Fragment>
            <section className={classes.bodyFormFlot}>
              {this.props.children}
            </section>
            <div className={classes.buttonArea}>
              <Button variant="contained" color="primary" onClick={submit} >Guardar</Button>
              <Button type="button" onClick={clearForm}>Limpiar</Button>
            </div>
          </React.Fragment>
        }

        </FloatingPanel>
    );
  }
}

AddCatalogue.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  clearForm: PropTypes.func, 
  withForm: PropTypes.bool
};

AddCatalogue.defaultProps = {
  withForm: true
}

export default  withStyles(styles) (AddCatalogue);
