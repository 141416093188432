import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Downshift from 'downshift';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import Loader from '../../../../components/Loader/Loader';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { apiPOST } from '../../../../services/service_base';
import { textLabelsEs } from '../../../../utils/tableTexts';
import {
  LanguageConsumer,
  LanguageCxt,
} from './../../../../language/LanguageContext';
import {
  getSuggestions,
  renderInput,
  renderSuggestion,
} from './../autocomplete';
import styles from './../reportes-jss';
import { IconButton, Tooltip } from '@material-ui/core';
import { PictureAsPdf } from '@material-ui/icons';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import classNames from 'classnames';

class UsuariosReporte extends React.Component {
  state = {
    //filters
    action: 0,
    fechaInicio: null,
    fechaFin: null,
    user: '',
    //Table
    columns: [],
    data: [],
    //Catalogues
    users: [],
    results: 0,
    isLoading: true,
  };


  handleDateChange = (date) => {
    this.setState({ fechaInicio: date });
  };

  handleDateChangeFin = (date) => {
    this.setState({ fechaFin: date });
  };


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStateChange = (changes) => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ user: changes.selectedItem });
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ user: changes.inputValue });
    }
  };

  handleClearFilters = () => {
    this.setState({
      action: 0,
      fechaInicio: null,
      fechaFin: null,
      user: '',
      isLoading: true,
    });

    const request = {
      start_date: 0,
      end_date: 0,
      action: 0,
      user: '0',
      portal: 'acervo',
    };

    this.handleFetch(request);
  };

  handleDownload = () => {
    const { lang } = this.context;
    const headers = [
      lang.reports.name,
      lang.reports.lastname,
      lang.reports.email,
      lang.reports.status,
      lang.reports.profile,
      lang.reports.lastAccess
    ];

    const result = [];
    for (const x in this.state.data) {
      var obj = [];
      obj.push(this.state.data[x][0]);
      obj.push(this.state.data[x][1]);
      obj.push(this.state.data[x][2]);
      obj.push(lang.userStatus[this.state.data[x][3]] ? lang.userStatus[this.state.data[x][3]] : '-');
      obj.push(this.state.data[x][4]);
      obj.push(moment(this.state.data[x][5]).format("DD/MM/YYYY hh:mm:ss"))
      result.push(obj);
    }

    const doc = new jsPDF({
      orientation: "landscape",
      format: ' legal'
    })
    doc.setFontSize(8)
    jsPDF.autoTableSetDefaults({
      headStyles: { fillColor: [241, 32, 101] }
    }, doc);
    doc.autoTable({
      head: [headers],
      body: result,
      startY: 10,
      margin: 20,
      showHead: 'firstPage',
      styles: { overflow: "linebreak", cellWidth: "auto" },
      columnStyles: { 2: { cellWidth: 50 }, 3: { cellWidth: 30 } },
    })
    doc.save(`${this.context.lang.reports.usuarios}.pdf`)
  }

  searchs = () => {
    this.setState({ isLoading: true })
    var fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);
    var ff = this.state.fechaFin === null ? 0 : new Date(this.state.fechaFin)

    const request = {
      start_date: fi === 0 ? 0 : fi.getDate() + "/" + (fi.getMonth() + 1) + "/" + fi.getFullYear(),
      end_date: ff === 0 ? 0 : ff.getDate() + "/" + (ff.getMonth() + 1) + "/" + ff.getFullYear(),
      user: this.state.user === "" ? "0" : this.state.user === "TODOS" ? "0" : this.state.user
    }

    this.handleFetch(request);
  };

  componentDidMount() {
    const { lang } = this.context;

    this.setState({
      columns: [
        lang.reports.name,
        lang.reports.lastname,
        lang.reports.email,
        lang.reports.status,
        lang.reports.profile,
        {
          name: "startDateNumber",
          label: lang.reports.lastAccess,
          options: {
            filter: true,
            sort: true,
            customBodyRender: value =>
              moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
          }
        }
      ],
    });

    apiPOST('/catalogue/get', { idCatalogo: 14 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ users: response.data })
        } else {
          console.log("Error in catalogue users: ", response);
        }
      }).catch(
        error => {
          console.log("Error in catalogue users: ", error);
        });

    const request = {
      start_date: 0,
      end_date: 0,
      user: 0
    }

    this.handleFetch(request);
  }

  handleFetch = (request) => {
    const { lang } = this.context;

    apiPOST('/reports/getusers', request).then(
      response => {
        this.setState({ isLoading: true })

        if (response.status === 200) {

          const result = [];
          for (const x in response.data) {
            var obj = [];
            obj.push(response.data[x].nombre);
            obj.push(response.data[x].apellidos);
            obj.push(response.data[x].usuario_cognito);
            obj.push(lang.userStatus[response.data[x].estatus] ? lang.userStatus[response.data[x].estatus] : '-');
            obj.push(response.data[x].tipo_usuario);
            obj.push(response.data[x].action_date)
            result.push(obj);
          }
          this.setState({ data: result, isLoading: false, results: response.data.length })

        } else {
          this.setState({ openError: true, isLoading: false });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false });
        }
      );
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    //AHDA 29/04/2019
    //Changed filterType
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: true,
      downloadOptions: {
        filename: `${this.context.lang.reports.usuarios}.csv`,
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: (e) => {
        return (
          <Tooltip title="Descargar PDF">
            <IconButton className='btn-toolbar' onClick={() => this.handleDownload()}>
              <PictureAsPdf />
            </IconButton>
          </Tooltip>
        );
      },
    };

    return (
      <LanguageConsumer>
        {({ lang }) => (
          <div className="marginTopLogo">
            <PapperBlock title={lang.reports.reports} whiteBg desc="">

              <form className={classes.container}>
                <Grid
                  container
                  spacing={24}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid item xs={6} sm={3} md={3}>
                      <DatePicker
                        keyboard={false}
                        helperText={lang.reports.startDate}
                        className={classes.formControl}
                        format="DD/MM/YYYY"
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,]}
                        value={this.state.fechaInicio}
                        onChange={this.handleDateChange}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        disableFuture={true}
                        clearable={true}
                        cancelLabel={lang.common.cancel}
                        clearLabel={lang.common.clear}
                        placeholder="DD/MM/AAAA"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <DatePicker
                        keyboard={false}
                        // helperText={lang.reports.compDateEnter}
                        helperText={lang.reports.endDate}
                        className={classes.formControl}
                        format="DD/MM/YYYY"
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,]}
                        value={this.state.fechaFin}
                        onChange={this.handleDateChangeFin}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        disableFuture={true}
                        clearable={true}
                        minDate={this.state.fechaInicio}
                        disabled={this.state.fechaInicio == null ? true : false}
                        cancelLabel={lang.common.cancel}
                        clearLabel={lang.common.clear}
                        placeholder="DD/MM/AAAA"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <Grid item xs={12} sm={3} md={3} className={classes.positionRelative}>
                    <div className={classes.root}>
                      <Downshift selectedItem={this.state.user} onStateChange={this.handleStateChange}>
                        {({
                          getInputProps,
                          getItemProps,
                          isOpen,
                          inputValue,
                          selectedItem,
                          highlightedIndex
                        }) => (
                          <div className={classes.formControl}>
                            {renderInput({
                              fullWidth: true,
                              classes,
                              helperText: lang.reports.user,
                              InputProps: getInputProps({
                                placeholder: lang.reports.user,
                                id: 'user',
                                name: 'user',
                              }),
                            })}
                            {isOpen ? (
                              <Paper className={classNames(classes.paper, classes.list)} square>
                                {getSuggestions(inputValue, this.state.users.filter(e => e.label !== "")).map((suggestion, index) =>
                                  renderSuggestion({
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({ item: suggestion.label }),
                                    highlightedIndex,
                                    selectedItem,
                                  }),
                                )}
                              </Paper>
                            ) : null}
                          </div>
                        )}
                      </Downshift>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={24}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Button
                      onClick={this.searchs}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {lang.searchs.search}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleClearFilters}
                    >
                      Limpiar
                    </Button>
                  </Grid>
                </Grid>
              </form>


              <div className={classes.bitacoraMov}>
                {this.state.isLoading ? (
                  <Loader text={'Cargando'} />
                ) : (
                  <MUIDataTable
                    title={`${lang.reports.usuarios} (${this.state.results} ${lang.reports.results})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                )}
              </div>
            </PapperBlock>
          </div>
        )}
      </LanguageConsumer>
    );
  }
}

UsuariosReporte.contextType = LanguageCxt;

export default withStyles(styles)(UsuariosReporte);
