import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
//Styles
import styles from './nuevaObra-jss';

// Modal
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import NuevaObra from './NuevaObra';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class NewWorkDialog extends React.Component{
    render(){
        //AHDA 29/04/2019
        //Added isEdit and title
        //isanchez 07.05.2019 add props for permissions
        const {classes, openStep, handleCloseStep, openFatalError, isEdit, title, canAddMaterial, canEvaluate, canEdit, canViewSinopsis, canViewPersonaje, canViewVigencia, canViewEquipoLit,canViewMoreInfo} = this.props; 
        
        return(
            <Dialog
                  fullScreen
                  open={openStep}
                  onClose={handleCloseStep}
                  TransitionComponent={Transition}
                >
                <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbarHeader}> 
                    {/*AHDA 29/04/2019: Changed title depending if is editing or not*/}
                    <Typography variant="title" color="inherit" className={classes.flex}>
                    { isEdit ?  `Edición de obra - ${title}` : "Nueva Obra"}
                    </Typography>
                    <IconButton color="inherit" onClick={handleCloseStep} aria-label="Close">
                    <CloseIcon />
                    </IconButton>
                </Toolbar>
                </AppBar>
                {/* isanchez 07.05.2019 add props to Component */}
                <NuevaObra 
                    handleCloseStep={handleCloseStep} 
                    openFatalError={openFatalError}
                    workId={this.props.workId}
                    sendNotification={this.props.sendNotification}
                    canAddMaterial={canAddMaterial}
                    canViewVigencia={canViewVigencia}
                    canViewEquipoLit={canViewEquipoLit}
                    canViewMoreInfo={canViewMoreInfo}
                    canEvaluate={canEvaluate}
                    canViewSinopsis={canViewSinopsis}
                    canViewPersonaje={canViewPersonaje}
                    canEdit={canEdit}
                />
            </Dialog>
        )
    }
}

//AHDA 29/04/2019
//Added isEdit and title
NewWorkDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    openStep: PropTypes.bool.isRequired, 
    handleCloseStep: PropTypes.func.isRequired,
    openFatalError: PropTypes.func, 
    isEdit: PropTypes.bool, 
    title: PropTypes.string,
    canEdit:PropTypes.bool,
    canAddMaterial: PropTypes.bool,
    canViewVigencia: PropTypes.bool,
    canViewEquipoLit: PropTypes.bool,
    canViewMoreInfo:PropTypes.bool,
    canEvaluate : PropTypes.bool
}

//AHDA 29/04/2019
//Added isEdit and title
NewWorkDialog.defaultProps = {
    handleCloseStep: () => {}, 
    isEdit: false, 
    title: "",
    canAddMaterial: true,
    canEvaluate : true,
    canEdit: true,
    canViewSinopsis:true,
    canViewPersonaje:true,
    canViewVigencia:true,
    canViewEquipoLit:true,
    canViewMoreInfo:true
}

export default withStyles(styles)(NewWorkDialog);