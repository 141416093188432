import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { getFileTitle } from '../../helpers/general';
import { Edit } from '@material-ui/icons';

let counter = 0;

function desc(a, b, orderBy) {
    // cramirez 20/8/2019
    if (orderBy === 'name') {
        var btemp = [], atemp = [];
        var bNum, aNum;
        if (b.name) {
            btemp = b[orderBy].split(/(\d+)/)
        } else {
            btemp = b.file[orderBy].split(/(\d+)/)
        }

        if (a.name) {
            atemp = a[orderBy].split(/(\d+)/)
        } else {
            atemp = a.file[orderBy].split(/(\d+)/)
        }
        if (atemp[0] === btemp[0]) {

            btemp.forEach(element => {
                if (Number.isInteger(parseInt(element))) {
                    bNum = parseInt(element)
                }
            });
            atemp.forEach(element => {
                if (Number.isInteger(parseInt(element))) {
                    aNum = parseInt(element)
                }
            });
            if (aNum && bNum) {

                if (bNum < aNum) {
                    return -1;
                }
                if (bNum > aNum) {
                    return 1;
                }

            }

        } else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }


        }
    } else {

        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }

    }
    return 0;
}



function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index + 1]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {

    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre del archivo' },
    { id: 'idioma', numeric: true, disablePadding: false, label: 'Idioma' },
    { id: 'fecha', numeric: true, disablePadding: false, label: 'Fecha de creación' },
    { id: 'description', numeric: true, disablePadding: false, label: 'Descripción' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Cantidad' },
    { id: 'edit', numeric: true, disablePadding: false, label: '' },
];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {rows.map(row => (
                        <TableCell
                            key={row.id}
                            align={row.numeric ? 'right' : 'left'}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={this.createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>

                        </TableCell>
                    ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.primary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

let EnhancedTableToolbar = props => {
    const { numSelected, classes, selectedItems, onDelete, onEdit } = props;

    const handleDelete = () => {
        onDelete(selectedItems, "libreto", "new_mScripts");
    }

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        Libretos
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {/* JCMY 05/07/2019  incidencia 603*/}
                <Tooltip title="Eliminar">
                    <div>
                        <IconButton aria-label="Delete" onClick={handleDelete} disabled={numSelected <= 0 ? true : false}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    selectedItems: PropTypes.array
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        width: '100%'
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    pointerCell: {
        cursor: "pointer"
    }
});

class EnhancedTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'name',
        selected: [],
        //Get all item
        selectedItems: [],
        data: [],
        page: 0,
        rowsPerPage: 5,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState({ selected: this.props.data.map(n => n.id_material_multimedia), selectedItems: [...this.props.data] });
            return;
        }
        this.setState({ selected: [], selectedItems: [] });
    };

    handleClick = (event, id) => {
        const { selected, selectedItems } = this.state;
        const { data } = this.props;

        const selectedIndex = selected.indexOf(id);
        let newSelected = [], newSelectedItems = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            newSelectedItems = newSelectedItems.concat(selectedItems, data.find(d => d.id_material_multimedia === id));
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newSelectedItems = newSelectedItems.concat(selectedItems.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newSelectedItems = newSelectedItems.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            //Check if it works the same
            newSelectedItems = newSelectedItems.concat(
                selectedItems.splice(selectedIndex, 1)
            )
        }

        this.setState({ selected: newSelected, selectedItems: newSelectedItems });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleClickRow = (file) => {
        if (file) {
            let auxUrl = encodeURIComponent(file.ruta)
            window.open(`/file?doc=${auxUrl}`, "_blank");
        }
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes, data, onDelete, onEdit } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, selectedItems } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        return (
            <Paper className={classes.root}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    selectedItems={selectedItems}
                    onDelete={onDelete}
                />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort().map(n => {
                                const isSelected = this.isSelected(n.id_material_multimedia);
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={n.id_material_multimedia}
                                        selected={isSelected}
                                    >
                                        <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id_material_multimedia)}>
                                            <Checkbox checked={isSelected} />
                                        </TableCell>
                                        <TableCell className={classes.pointerCell} component="th" scope="row" padding="none" onClick={event => this.handleClickRow(n)}>
                                            {!n.new ? n.name : n.file.name}
                                        </TableCell>
                                        {/* JCMY 22/07/2019 incidecnia 1078*/}
                                        <TableCell align="right">{n.language}</TableCell>
                                        <TableCell align="right">{n.creation_date}</TableCell>
                                        <TableCell align="right">{n.description}</TableCell>
                                        <TableCell align="right">{n.amount}</TableCell>
                                        <TableCell padding="checkbox">
                                            {n.new&&<IconButton
                                                className={classes.button}
                                                onClick={() => onEdit(n, "libreto", "new_mScripts")}
                                            >
                                                <Edit className={classes.iconxs} />
                                            </IconButton>}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    onDelete: PropTypes.func
};

export default withStyles(styles)(EnhancedTable);