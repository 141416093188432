import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import SweetAlert from 'sweetalert-react';
import Tooltip from '@material-ui/core/Tooltip';

//Estilos
import styles from './styles-jss'
import '../../styles/components/vendors/sweetalert-react/sweetalert.css';

//Iconos
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//Tabla
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { CloudDownload } from '@material-ui/icons';

// JCMY 22/07/2019 incidencia 1073

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

class TableLibretos extends React.Component {
    state = {
        show: false,
        selected: null,
        page: 0,
        rowsPerPage: 5,
        // JCMY 22/07/2019 incidencia 1073
        order: 'asc',
        orderBy: 'idioma',
    };

    handleOpenSwal = (dat) => {
        this.setState({ show: true, selected: dat });
    }

    handleConfirmSwal = () => {
        this.setState({ show: false });
        this.props.deleteItem(this.state.selected);
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {

        const { headers, classes, data, handleDownload, clickView, canView } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;//JCMY 22/07/2019

        return (
            <div className={classes.ajusteTabla}>
                <Table className={classNames(classes.table, classes.stripped)}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, idx) =>
                            (
                                <TableCell key={`header_${idx}`}>{header}</TableCell>
                            ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* JCMY 22/07/2019 incidencia 1073 */}
                        {stableSort(data, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(n => {
                                return (
                                    <TableRow key={n.id_material_multimedia}>
                                        <TableCell >{n.name}</TableCell>
                                        <TableCell>{n.idioma}</TableCell>
                                        <TableCell>{n.fecha}</TableCell>
                                        <TableCell>{n.description}</TableCell>
                                        <TableCell>{n.amount}</TableCell>
                                        <TableCell padding="checkbox">
                                            {
                                                canView ?
                                                    <Tooltip title="Ver"><IconButton aria-label="Visibility" className={classes.margin} onClick={() => clickView(n.url)}> <Visibility fontSize="small" /> </IconButton></Tooltip>
                                                    : <Tooltip title="Ver"><IconButton aria-label="VisibilityOff" className={classes.margin}> <VisibilityOff fontSize="small" /> </IconButton></Tooltip>
                                            }
                                            <Tooltip title="Descargar"><IconButton aria-label="Visibility" className={classes.margin} onClick={() => handleDownload(n.url)}> <CloudDownload fontSize="small" /> </IconButton></Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {/* 

                        {
                            data.map((dat, idx) =>
                                (
                                    <TableRow key={`row_${idx}`}>
                                        {renderRow(dat)}
                                    </TableRow>

                                ))
                        } */}
                    </TableBody>

                    <SweetAlert
                        show={this.state.show}
                        customClass={classes.alertDialog}
                        title="¿Estás seguro?"
                        text="Una vez que elimines este elemento, no se podrá recuperar"
                        showCancelButton
                        onConfirm={() => {
                            this.handleConfirmSwal();
                        }}
                        onCancel={() => {
                            this.setState({ show: false, selected: null });
                        }}
                    />
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage={"Filas por página"}
                />
            </div>

        )
    }
}

//AHDA 25/04/2019
//Added blockedView
TableLibretos.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    openForm: PropTypes.func,
    deleteItem: PropTypes.func,
    updateStatus: PropTypes.func,
    showDelete: PropTypes.bool,
    clickView: PropTypes.func,
    canView: PropTypes.bool
};

//AHDA 25/04/2019
//Added blockedView
TableLibretos.defaultProps = {
    openForm: () => { },
    deleteItem: () => { },
    updateStatus: () => { },
    showDelete: true,
    canView: false
}

export default withStyles(styles)(TableLibretos);