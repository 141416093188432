import ReactGA from 'react-ga4';

const new_event = () => ReactGA.event({
    action: "nueva_obra",
    category: "admin_obra",
    label: "Formulario de nueva obra"
});

const search_event = (label) => ReactGA.event({
    action: "clicksearch",
    category: "Search",
    label
});

const filter_event = (category, label) => ReactGA.event({
    action: "clickfilter",
    category,
    label
});

const fav_event = (label) => ReactGA.event({
    action: "clickfavorite",
    category: "Favorite",
    label
});

const gaEvents = {
    eventTest: new_event,
    eventSearch: (label) => search_event(label),
    eventFilter: (category, label) => filter_event(category, label),
    eventFav: (label) => fav_event(label)
}

export { gaEvents }