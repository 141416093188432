import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { S3Image } from 'aws-amplify-react';
import classNames from 'classnames';

//Estilos
import '../../styles/components/vendors/slick-carousel/slick-carousel.css';
import '../../styles/components/vendors/slick-carousel/slick.css';
import '../../styles/components/vendors/slick-carousel/slick-theme.css';

const styles = ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    formControl: {
        width: '50%',
        margin: '0 auto'
    },
    item: {
        textAlign: 'center',
        '& img': {
            margin: '10px auto'
        }
    },
    slideSlickContainer: {
        width: '100%'
    },
    imgCarousel: {
        width: '100%',
        textAlign: 'center',
    }
});

class ThumbnailCarousel extends React.Component {
    state = {
        transition: 'slide'
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { classes, imgData } = this.props;
        const { transition } = this.state;
        const settings = {
            // customPaging: (i) => (
            //     <a>
            //         <img src={getThumb[i]} alt="thumb" />
            //     </a>
            // ),
            infinite: false, //AHDA 03/06/2019
            dots: true,
            centerMode: false,
            speed: 500,
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            cssEase: 'ease-out',
            fade: this.state.transition === 'fade',
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
              {
                breakpoint: 460,
                settings: {
                  arrows: true,
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
            ]
        };
        return (
            <div className={classNames(classes.slideSlickContainer, 'detalleSlider')}>
                <Slider {...settings}>
                    {imgData.map((item, index) => (
                        <Grid
                            container
                            alignItems="flex-start"
                            justify="flex-start"
                            direction="row"
                            spacing={8}
                            key={`img_${index}`}>
                            <Grid item xs={12} className={classes.imgCarousel}>
                                <div key={index.toString()} className={classes.item}>
                                    {/* CH 25/07/2019
                                    Se elimina medida fija. */}
                                    <S3Image
                                    // theme={{ photoImg: { width: '329px', height: '185px', objectFit: 'cover' } }} 
                                    imgKey={item.url} />
                                </div>
                            </Grid>
                        </Grid>
                    ))}
                </Slider>
            </div>
        );
    }
}

ThumbnailCarousel.propTypes = {
    classes: PropTypes.object.isRequired,
    imgData: PropTypes.array.isRequired
};

ThumbnailCarousel.defaultProps = {
    imgData: []
};

export default withStyles(styles)(ThumbnailCarousel);