module.exports = [
  {
    id: 1,
    key: 'acervo',
    name: 'Acervo',
    icon: 'dashboard',
    link: '/acervo'
  },
  {
    id:6,
    key: 'catalogos',
    name: 'Catalogos',
    icon: 'library_books',
  },
  {
    id:0,
    key: 'settings',
    name: 'Configuración',
    icon: 'settings',
    child: [
      {
        id:10,
        key: 'ConfigureNotifications',
        name: 'Configuración de notificaciones',
        link: '/configuracion/configuracion-de-notificaciones'
      },
      {
        id:3,
        key: 'obras_literarias',
        name: 'Obras Literarias',
        link: '/configuracion/obras-literarias'
      },
      {
        id:18,
        key: 'parameters',
        name: 'Parámetros',
        link: '/configuracion/parameters'
      },
      {
        id: 8,
        key: 'perfiles_privilegios',
        name: 'Perfiles y Privilegios',
        link: '/configuracion/perfiles-y-privilegios'
      },
      {
        id:7,
        key: 'users',
        name: 'Usuarios',
        link: '/configuracion/users'
      },
    ]
  },
  {
    id:5,    
    key: 'profile',
    name: 'Mi Perfil',
    icon: 'account_circle',
    link: '/profile'
  },
  {
    id:9,
    key: 'notifications',
    name: 'Notificaciones',
    icon: 'notifications',
    link: '/notifications'
  },
  {
    id:2,    
    key: 'reports',
    name: 'Reportes',
    icon: 'insert_chart',
    child: [
      {
        key: 'bitacora_movimientos',
        name: 'Bitácora Movimientos',
        link: '/reporte/bitacora-movimientos'
      },
      {
        key: 'composicion_acervo',
        name: 'Composición Acervo',
        link: '/reporte/composicion-acervo'
      },
      {
        key: 'favoritos',
        name: 'Favoritos',
        link: '/reporte/favoritos'
      },
      {
        key: 'consultas',
        name: 'Consultas',
        link: '/reporte/consultas'
      },
      {
        key: 'prospectos',
        name: 'Prospectos',
        link: '/reporte/prospectos'
      },
      {
        key: 'proyectos',
        name: 'Proyectos',
        link: '/reporte/proyectos'
      },
      {
        key: 'controlmateriales',
        name: 'Control de materiales',
        link: '/reporte/controlmateriales'
      }, 
      {
        key: 'usuarios',
        name: 'Usuarios',
        link: '/reporte/usuarios'
      }
    ]
  }
];
