import { Auth, Storage } from 'aws-amplify';
import { apiPOST } from './service_base'; 

//Save one file
export const saveFile = (path, file, level, version) => {
    return new Promise(
        function(resolve, reject){
            Storage.put(`${path}/${new Date().getTime()}_${version||file.name}`, file, {
                level: level, 
                contentType: file.type
            }).then(
                result => {
                    resolve(result); 
                }
            ).catch(
                err => {
                    reject(err); 
                }
            )
        }
    )
}

export const saveUserPhoto = (file, user) => {
    return new Promise(
        function(resolve, reject){
            Storage.put(`profilePicture/${new Date().getTime()}_user${user.id_usuario}_${file.name}`, file, {
                level: 'public', 
                contentType: file.type
            }).then(
                result => {
                    resolve(result); 
                }
            ).catch(
                err => {
                    reject(err); 
                }
            )
        }
    )
}

//Save multiple files user
export const saveMultipleFiles = (files, level ,cbProgress) => {
    
    let keys = []; 
    let errors = []; 

    let response = {
        message: "",
        keys: [], 
        errors: []
    }
    
    //Recibir metodo de guardar en rds 
    let promises = []; 
    files.map((file,fileIdx) => {
        promises.push(Storage.put(`${file.path}/${new Date().getTime()}_${file.file.name}`, file.file, {
            level: level, 
            contentType: file.type,
            progressCallback(uploadProgress) {
                     
                if (cbProgress) {
                    cbProgress(calculateProgress(fileIdx, files.length, uploadProgress))
                }
            }
        }).then(
            result => {
                file.ruta = result.key; 
                keys.push(file);  
                return "Success"; 
                
        }).catch(
            err => {
                errors.push(file);  
                return "Error";      
        }))
    }); 

    return Promise.all(promises).then(result => {
        console.log(result); 
        
        response.keys = keys; 
        response.errors = errors; 
        if(result){
            return response; 
        }
    })
    
    /*return new Promise(
        function(resolve, reject){
            if(files.length <= 0){
                response.message = "No files";
                reject(response); 
            }

            for(let x in files){
                Storage.put(`${file.path}/${new Date().getTime()}_${file.file.name}`, file.file, {
                    level: level, 
                    contentType: file.type
                }).then(
                    result => {
                        file.ruta = result.key; 
                        keys.push(file);  
                        if(x == files.length -1 ){
                            response.keys = keys; 
                            response.errors = errors; 
                            resolve(response); 
                        }
                }).catch(
                    err => {
                        errors.push(file); 
                        if(x == files.length -1 ){
                            response.keys = keys; 
                            response.errors = errors; 
                            resolve(response); 
                        }
                });
            }
        });*/
}

const calculateProgress = (fileIdx, filesCount, progress) => { 
    const { loaded, total } = progress;
    let totalPercentage = 0;
    if (loaded > 0 && (loaded !== null && total !== null)) {
        const percentagePerFile = 100 / filesCount;
        const filePercentage = 100 * (loaded / total);
        const currentPercentage = (filePercentage * (percentagePerFile / 100));
        totalPercentage = (percentagePerFile * fileIdx) + currentPercentage;
    }
  
    return totalPercentage;
}

//AHDA 11/04/2019
//Added expiration parameter and changed method
export const getFile = (key, level, expiration ,cbProgress) => { 
    return new Promise(
        function(resolve, reject){
            if(expiration){
                Storage.get(key, { level: level, expires: expiration }).then(
                    result => {
                        resolve(result); 
                }).catch(
                    err => {
                        reject(err); 
                })
            }else{
                Storage.get(key, { 
                    level: level,
                    progressCallback(uploadProgress) {
                     
                        if (cbProgress) {
                            cbProgress(calculateProgress(0, 1, uploadProgress))
                        }
                    }  
                }).then(
                    result => {
                        resolve(result); 
                }).catch(
                    err => {
                        reject(err); 
                })
            }
            
    }); 
}

//AHDA 11/04/2019
//Added method for multiple urls 
export const getMultipleFiles = (keys, level, expiration) => {
    let urls = [], promises = []; 

    keys.map(key => {
        promises.push(
            Storage.get(key.key, { level: level, expires: expiration }).then(
                result => {
                    urls.push({url: result, fileName: key.fileName}); 
                    return "Success"; 
            }).catch(
                err => {
                    return "Error"; 
            })
        )
    }); 

    return Promise.all(promises).then(result => {
        console.log(result); 
         
        if(result){
            return urls; 
        }
    }); 
}

export const getOtherUserFile = (key, user) => {
    return new Promise(
        function(resolve, reject){
            Storage.get(key, {  level: 'protected', identityId: user }).then(
                result => {
                    resolve(result); 
            }).catch(
                err => {
                    reject(err); 
            })
    }); 
}

export const deletePublicFile = (key) => {
    return new Promise(
        function(resolve, reject){
            Storage.remove(key).then(
                result => {
                    resolve(result); 
                }
            ).catch(
                err => {
                    reject(err); 
                }
            )
        }
    )
}

export const deletePrivateFile = (key, level) => {
    return new Promise(
        function(resolve, reject){
            Storage.remove(key, {level: level}).then(
                result => {
                    resolve(result); 
                }
            ).catch(
                err => {
                    reject(err); 
                }
            )
        }
    )
}

export const deleteFileComplete = (url, body, key) => {
    return new Promise(
        function(resolve, reject){
            apiPOST(url, body).then(
                response => {
                    // if(response.status === 200){
                    //     Storage.remove(key).then(
                    //         result => {
                    //             resolve(result); 
                    //         }
                    //     ).catch(
                    //         err => {
                    //             resolve(err);
                    //         }
                    //     )
                    // }else{
                        resolve(response);
                    // }
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
        }
    ); 
}

//AHDA 09/04/2019
//Create folder base with txt 
export const createFolderBase = (path, text) => {

    //TODO: add custom prefix and check if deletes all folder
    return new Promise(
        function(resolve, reject){
            //`In this folder you will find shared material with ${user.name}, from the work ${}`
            //new Date().getTime() + (5 * 60000); 
            
            Storage.put(`${path}/readme.txt`, text ? text : "", {level: "public"}).then(
                result => {
                    resolve(result); 
                }
            ).catch(
                err => {
                    reject(err); 
                }
            )

        }
    )
}