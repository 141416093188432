import React from 'react';

import './infinito.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import PapperBlock from '../PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core';
import { style } from './style';

class LoaderFull extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className="body">
                <div className="loader-wrapper-infinite">
                    <div className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader2" className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader3" className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader4" className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>
                    <div id="loader5" className="loader-infinite">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>
                </div>
                <div className={'loader-progress-wrapper'}>
                    {this.props.process && (
                        <PapperBlock whiteBg noTitle className={classes.progressContainer}>
                            <LinearProgress
                                color="primary"
                                variant="determinate"
                                value={this.props.process}
                            />
                        </PapperBlock>
                    )}
                </div>
            </div>
        );
    }
}

export default withStyles(style)(LoaderFull);
