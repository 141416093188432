import ls from 'local-storage';

export const getObjectById = (data, item) => {
    let auxItem = data.find(itm => {
        return itm.id === item.id;
    })
    return auxItem; 
}

export const getFileTitle = (url) => {
    let auxUrl = url.split('/'); 
    auxUrl = auxUrl.length > 0 ? auxUrl[auxUrl.length - 1].split('_') : "";
    auxUrl.splice(0,1);
    auxUrl = auxUrl.join("_"); 
    return auxUrl;  
}

//AHDA 25/04/2019
//Auxiliar method to get redirect url 
export const getRedirectUrl = (config) => {
    let redirect = "", isRedirect = false; 
    for(let x in config){
        const permissions = JSON.parse(config[x].grant_configuration); 
        let breakFor = false; 
        switch(config[x].menu_id){
          case 2:
            if(permissions.chkShowMovementHistory){
                redirect = "/reporte/bitacora-movimientos"; 
                isRedirect = true; 
            }
            else if(permissions.chkCompAcervoLiterario){
                redirect = "/reporte/composicion-acervo"; 
                isRedirect = true; 
            }
            else if(permissions.chkWorkMoreView){
                redirect = "/reporte/consultas"; 
                isRedirect = true; 
            }
            else if(permissions.chkViewFavorite){
                redirect = "/reporte/favoritos"; 
                isRedirect = true; 
            }
            else if(permissions.chkViewProspect){
                redirect = "/reporte/prospectos"; 
                isRedirect = true; 
            }
            else if(permissions.chkViewProject){
                redirect = "/reporte/proyectos"; 
                isRedirect = true; 
            }else if(permissions.chkViewControlMat){
                redirect = "/reporte/controlmateriales"; 
                isRedirect = true; 
            }else if(permissions.chkViewUsers){
                redirect = "/reporte/usuarios"; 
                isRedirect = true; 
            }

            breakFor = true; 
          break; 
          case 3:
            if(permissions.chkViewWorkAdmin){
              redirect = "/configuracion/obras-literarias"; 
              isRedirect = true; 
              breakFor = true; 
            }else{
              isRedirect = false;
            }
          break; 
          case 5:
            if(permissions.chkViewProfile){
                redirect = "/profile"; 
                isRedirect = true; 
                breakFor = true; 
            }else{
                isRedirect = false;
            }
          break; 
          case 6:
            if(permissions.chkViewCatalog){
                redirect = "/catalogos/ambiente"; 
                isRedirect = true; 
                breakFor = true; 
            }else{
                isRedirect = false;
            }
          break; 
          case 7:
            if(permissions.chkViewUser){
                redirect = "/configuracion/users"; 
                isRedirect = true; 
                breakFor = true; 
            }else{
                isRedirect = false;
            }
          break; 
          case 8:
            if(permissions.chkViewProfileAdmin){
                redirect = "/configuracion/perfiles-y-privilegios"; 
                isRedirect = true; 
                breakFor = true; 
            }else{
                isRedirect = false;
            }
          break; 
          case 10:
            if(permissions.chkViewConfig){
                redirect = "/configuracion/configuracion-de-notificaciones"; 
                isRedirect = true; 
                breakFor = true; 
            }else{
                isRedirect = false;
            }
          break; 
          case 18:
            if(permissions.chkViewProfile){
                redirect = "/configuracion/parameters"; 
                isRedirect = true; 
            }else{
                isRedirect = false;
            }
          break; 
          default: 
          isRedirect = false; 
          break; 
        } 

        if(breakFor){
            break; 
        }
    }

    if(isRedirect){
        ls.set("redirect_url", redirect); 
        return redirect; 
    }else{
        return false
    }
}

export const getCleanTitle = (string) => {
    string = string.normalize('NFD').replace(/[\u0300-\u036f]/g, ""); 
    string = string.replace(/ /g, "_"); 
    return string; 
}

//AHDA 21/05/2019
export const getFilteredData = (data, key, search) => {
    
    data = data ?  data.filter(item => {
        if(item[key]){
            if(item[key].toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1)
                return item; 
        }
    }) : []; 

    return data; 
}

//AHDA 21/05/2019
export const getfilteredDataAllKeys = (data, search) => {
    let auxData = [...data];
    let filteredData = [];  
    
    auxData.map(item => {
        //console.log(Object.values(item)); 
        const match = Object.values(item).filter(v => {
            if(v){
                if(v.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1)
                    return v;  
            }
        }); 

        let hasMatch = match.length > 0 ? true : false; 

        if(hasMatch){
            filteredData.push(item); 
        }
        return item;  
    }); 

    return filteredData; 
}