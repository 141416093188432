import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ProductCard from './../CardPaper/ProductCard';
import Typography from '@material-ui/core/Typography';

//Pagination
import Pagination from './../Pagination/Pagination';

//Styles 
import styles from './profile-jss'


class PaginationCard extends React.Component {
    constructor(props){
        super(props); 
        
        this.state = {
            page: 1, 
            contentsPerPage: this.props.contentsPerPage, 
            content: this.props.works
        }
    }

    //Pagination
    onPageChange(page) {
        this.setState({ page: page });
    }

    onPrev(totalPages) {
        if (this.state.page > totalPages) {
            let page = this.state.page
            this.setState({ page: page -= 1 });
        } else {
            this.setState({ page: 1 });
        }
    }
    
    onNext(totalPages) {
        if (this.state.page < totalPages) {
            let page = this.state.page; 
            this.setState({ page: page += 1 });
        } else {
            this.setState({ page: totalPages });
        }
    }
    
    onGoFirst() {
        this.setState({ page: 1 });
    }

    onGoLast(totalPages) {
        this.setState({ page: totalPages });
    }
    
    render(){
        const { page, content, contentsPerPage } = this.state;

        const {
            handleNotifications, 
            handleDetailOpen, 
            paginate, 
            list, 
            showDate, 
            //AHDA 24/04/2019
            //Added canFavorite
            canFavorite,
            canAddProspect,
            canViewWorkBlock, 
            chkProductionGeneralView, //AHDA 03/06/2019
            chkSalesGeneralView, //AHDA 03/06/2019
            imagenLarge //CH 21/06/2019
        } = this.props; 

        const indexOfLastWork = page * contentsPerPage;
        const indexOfFirstWork = indexOfLastWork - contentsPerPage;
        const currentContent = content.slice(indexOfFirstWork, indexOfLastWork);

        const renderContent = currentContent.map((product, index) => (
            /*CH 13/05/2019: Se cambia grid*/  
            <Grid item xl={list ? 12 : 2} lg={list ? 12 : 3}  md={list ? 12 : 3} sm={list ? 12 : 4} xs={12} key={`workCard_${index}`}>
                {/*AHDA 03/05/2019: Added canViewSales, canViewProduction*/}
                <ProductCard
                lista={list}
                list={list}
                detailOpen={() => 
                    handleDetailOpen(product)                        
                }
                handleNotifications={() => handleNotifications()}
                product={product}  
                screen={'profile'}
                showDate={showDate}
                canFavorite={canFavorite}
                canAddProspect={canAddProspect}
                canViewWorkBlock={canViewWorkBlock}
                canViewProduction={chkProductionGeneralView}
                canViewSales={chkSalesGeneralView}
                imagenLarge={this.props.imagenLarge}
                />
            </Grid> 
        ));
        
        const pageNumbers = [];
            for (let i = 1; i <= Math.ceil(content.length / contentsPerPage); i += 1) {
            pageNumbers.push(i);
        }
        
        return(
            <div>
                <Grid container alignItems="flex-start" justify="flex-start" direction="row" spacing={16}>
                    {
                        paginate ? 
                        renderContent :
                        content.map((product, index) => (
                            /*CH 13/05/2019: Se cambia grid*/  
                            <Grid item xl={list ? 12 : 2} lg={list ? 12 : 3}  md={list ? 12 : 3} sm={list ? 12 : 4} xs={12} key={`workCard_${index}`}>
                                {/*AHDA 03/05/2019: Added canViewSales, canViewProduction*/}
                                <ProductCard
                                lista={list}
                                list={list}
                                detailOpen={() => 
                                    handleDetailOpen(product)                        
                                }
                                handleNotifications={() => handleNotifications()}
                                product={product}  
                                screen={'profile'}
                                showDate={showDate}
                                canFavorite={canFavorite}
                                canAddProspect={canAddProspect}
                                canViewWorkBlock={canViewWorkBlock}
                                
                                canViewProduction={chkProductionGeneralView}
                                canViewSales={chkSalesGeneralView}
                                imagenLarge={this.props.imagenLarge}
                                />
                            </Grid> 
                        ))
                    }
                </Grid>

                {
                    content.length <= 0 ? null : paginate ? 
                    <Pagination
                    curpage={page}
                    totpages={pageNumbers.length}
                    boundaryPagesRange={1}
                    onChange={(page) => this.onPageChange(page)}
                    siblingPagesRange={1}
                    hideEllipsis={false}
                    onPrev={() => this.onPrev(pageNumbers.length)}
                    onNext={() => this.onNext(pageNumbers.length)}
                    onGoFirst={() => this.onGoFirst(pageNumbers.length)}
                    onGoLast={() => this.onGoLast(pageNumbers.length)}
                    /> : null
                }
                
            </div>
           
        )
    }
}


PaginationCard.propTypes = {
    classes: PropTypes.object.isRequired,
    handleNotifications: PropTypes.func, 
    handleDetailOpen: PropTypes.func, 
    works: PropTypes.array.isRequired, 
    paginate:  PropTypes.bool, 
    list: PropTypes.bool, 
    contentsPerPage: PropTypes.number, 
    showDate: PropTypes.bool, 
    //AHDA 24/04/2019
    //Added canFavorite
    canFavorite: PropTypes.bool,
    canAddProspect: PropTypes.bool,
    canViewWorkBlock: PropTypes.bool, 
    chkProductionGeneralView: PropTypes.bool, //AHDA 03/06/2019
    chkSalesGeneralView: PropTypes.bool, //AHDA 03/06/2019
    imagenLarge: PropTypes.bool, //CH 21/06/2019
}

PaginationCard.defaultProps = {
    handleNotifications: () => {},
    handleDetailOpen: () => {}, 
    works: [], 
    paginate: true, 
    list: false, 
    contentsPerPage: 8, 
    showDate: false, 
    //AHDA 24/04/2019
    //Added canFavorite
    canFavorite: false,
    canAddProspect: false,
    canViewWorkBlock: false,
    chkProductionGeneralView: true, //AHDA 03/06/2019
    chkSalesGeneralView: true, //AHDA 03/06/2019
    imagenLarge: false, //CH 21/06/2019
}
export default withStyles(styles)(PaginationCard);