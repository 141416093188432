import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardHeader, Checkbox, Chip, Grid, InputLabel, Typography, withStyles } from '@material-ui/core'
import styles from './product-jss'
import classNames from 'classnames'
import { getChipClassNames } from './product-utils'
import moment from 'moment'

const TabVigencias = ({ classes, data }) => {

    data.options = data.options ? typeof data.options === 'object' ? data.options : JSON.parse(data.options) : {};

    return (
        <>
            <div className={classes.chipContainer}>
                {data.type && <Chip label={data.type} className={getChipClassNames(classes, data.type)} />}
            </div>
            <Card>
                <CardHeader subheader={"Vigencias"} className={classes.header} />
                <CardContent>
                    {data.id_obra_vigencia ?
                        <Grid>
                            {data.date &&
                                <Grid container spacing={32}>
                                    <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                        <InputLabel>Mes: </InputLabel>
                                        <Typography variant='body1' component="span" className={classes.optionValue}>{moment(data.date).format('MMMM')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                        <InputLabel>Año: </InputLabel>
                                        <Typography variant='body1' component="span" className={classes.optionValue}>{moment(data.date).format('YYYY')}</Typography>
                                    </Grid>
                                </Grid>
                            }
                            {data.options.pup !== undefined && <Grid container spacing={32}>
                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <Typography variant='body1' component="span" className={classNames(classes.optionLabel, classes.optionLabel80)}>PUP</Typography>
                                    <Checkbox
                                        className={classes.optionValue}
                                        checked={data.options.pup}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <InputLabel>Año: </InputLabel>
                                    <Typography variant='body1' component="span" className={classes.optionValue}>{data.options.year}</Typography>
                                </Grid>

                            </Grid>
                            }
                            {data.options.perpetuo !== undefined && <Grid container spacing={32} >
                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <Typography variant='body1' component="span" className={classNames(classes.optionLabel, classes.optionLabel80)}>Perpetuidad</Typography>
                                    <Checkbox
                                        className={classes.optionValue}
                                        checked={data.options.perpetuo}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            }
                            {data.options.territorio && <Grid container spacing={32}>
                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <Typography variant='body1' component="span" className={classes.optionLabel}>Territorio restringido:</Typography>
                                    <Typography variant='body1' component="span" className={classes.optionValue}>{data.options.territorio}</Typography>
                                </Grid>
                            </Grid>
                            }
                            {data.comment && <Grid container spacing={32}>
                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <Typography variant='body1' component="span" className={classes.optionLabel}>Comentario:</Typography>
                                    <Typography variant='body1' component="span" className={classes.optionValue}>{data.comment}</Typography>
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                        : <InputLabel >No hay información disponible</InputLabel>}
                </CardContent>
            </Card>
        </>
    )
}

TabVigencias.propTypes = {
    data: PropTypes.array
}

export default withStyles(styles)(TabVigencias);