import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { Helmet } from 'react-helmet';
// import brand from '../../../../utils/brand';
import PapperBlock from './../../../../components/PapperBlock/PapperBlock';
import Typography from '@material-ui/core/Typography';
import LoaderFull from '../../../../components/Loader/LoaderFull'; 
import Loader from '../../../../components/Loader/Loader'; 

// MODAL
//AHDA 04/04/2019
//Unused dependencies deleted
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';

// tabs
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// notificaciones
import Pagination from './PaginationNotification';

// iconos
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import Create from '@material-ui/icons/Create';
import FileUpload from '@material-ui/icons/FileUpload';
import Alarm from '@material-ui/icons/Alarm';
import Block from '@material-ui/icons/Block';
import Check from '@material-ui/icons/Check';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import NoteAdd from '@material-ui/icons/NoteAdd';
import LockOpen from '@material-ui/icons/LockOpen';
import ls from 'local-storage';
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import { withRouter } from 'react-router-dom';

// TABLA LIBRETOS
import TableElegirLibretto from './TableElegirLibretto';

//AHDA 05/04/2019
//Added dependencies for error notifications, and helpers
//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

//Helpers 
import { getFileTitle } from '../../../../helpers/general';
//AHDA 08/04/2019
//Added dependencies for sending notifications 
import { sendEmail, sendNotification} from '../../../../services/notifications'; 
import { notifTypes } from '../../../../utils/notifTypes';


function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
        margin: '10px auto'
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    alignMsj: {
        paddingBottom: '50px !important;',
        padding: 50,
        textAlign: 'center',
    },
    textMsj: {
        color: '#999999',
    },
    // MODAL
    appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      detailContainer: {
        margin: '-16px auto 0',
        width: '100%',
        paddingTop: 40,
        [theme.breakpoints.up('lg')]: {
          maxWidth: 1080,
          minWidth: 1080,
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: 960,
          minWidth: 960,
          paddingTop: 40,
          marginTop: 0
        },
        [theme.breakpoints.down('sm')]: {
          //overflowX: 'hidden',
        }
      },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

class Notifications extends React.Component {

    //AHDA 04/04/2019
    //Added permissions for scripts and options for notifications
    //Added state for scripts
    state = {
        value: 0,
        //Notifications array
        notNewWorkRegister: [],
        notWorkUpdate: [],
        notEvaluationAssessment: [],
        notScriptsAccessRequest: [],
        notScriptsAccess: [],
        notDueTimeProspect: [],
        notWorkAssigned: [],
        notWorkProspectUser: [],
        notAditionalMaterialRegister: [],
        notUnlockWorkPropspect: [],
        //Loading
        isLoading: true, 
        notifications: [], 
        //Tabs
        tabs: [],
        //Permissions
        chkEditNotification: false,
        chkViewNotification: false,
        chkPickScripts: false, 
        //Scripts dialog
        open: false,
        //Notifications options
        defaultOptions: [{name: "Eliminar", permission: "canDelete", action: "handleDeleteItem", type:1}], 
        scriptsOptions: [
            {name: "Eliminar", permission: "canDelete", action: "handleDeleteItem", type:1}, 
            {name: "Elegir libretos", permission: "canPickScripts", action: "handleClickOpen", type:2}
        ],
        //Scripts 
        isLoadingDialog: false, 
        scripts: [], 
        rawScripts: [], 
        selectedNotification: undefined,
        //Error notifications 
        openError: false, 
        openDialogError: false,
        notifType: "error",
        notifMsg: "Algo salió mal, intente de nuevo más tarde",
        notificationSelected:{}// JCMY 10/07/2019 incidencia 1004
    };

    //-------------------------- Tabs 
    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };
    //-------------------------- End Tabs 

    //-------------------------- Notifications functionality
    handleDeleteItem = (item) => {
        apiPOST('/notifications/delete', { idNotificacion: item.id_notificaciones }).then(
            response => {
                if (response.status === 200) {
                    let index = 0

                    switch (item.type) {
                        case "newWorkRegister":
                            index = this.state.notNewWorkRegister.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notNewWorkRegister = this.state.notNewWorkRegister
                            notNewWorkRegister.splice(index, 1);
                            this.setState({ notNewWorkRegister: notNewWorkRegister });
                            break;
                        case "workUpdate":
                            index = this.state.notWorkUpdate.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notWorkUpdate = this.state.notWorkUpdate
                            notWorkUpdate.splice(index, 1);
                            this.setState({ notWorkUpdate: notWorkUpdate });
                            break;
                        case "evaluationAssessment":
                            index = this.state.notEvaluationAssessment.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notEvaluationAssessment = this.state.notEvaluationAssessment
                            notEvaluationAssessment.splice(index, 1);
                            this.setState({ notEvaluationAssessment: notEvaluationAssessment });
                            break;
                        case "scriptsAccessRequest":
                            index = this.state.notScriptsAccessRequest.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notScriptsAccessRequest = this.state.notScriptsAccessRequest
                            notScriptsAccessRequest.splice(index, 1);
                            this.setState({ notScriptsAccessRequest: notScriptsAccessRequest });
                            break;
                        case "scriptsAccess":
                            index = this.state.notScriptsAccess.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notScriptsAccess = this.state.notScriptsAccess
                            notScriptsAccess.splice(index, 1);
                            this.setState({ notScriptsAccess: notScriptsAccess });
                            break;
                        case "dueTimeProspect":
                            index = this.state.notDueTimeProspect.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notDueTimeProspect = this.state.notDueTimeProspect
                            notDueTimeProspect.splice(index, 1);
                            this.setState({ notDueTimeProspect: notDueTimeProspect });
                            break;
                        case "workAssigned":
                            index = this.state.notWorkAssigned.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notWorkAssigned = this.state.notWorkAssigned
                            notWorkAssigned.splice(index, 1);
                            this.setState({ notWorkAssigned: notWorkAssigned });
                            break;
                        case "workProspectUser":
                            index = this.state.notWorkProspectUser.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notWorkProspectUser = this.state.notWorkProspectUser
                            notWorkProspectUser.splice(index, 1);
                            this.setState({ notWorkProspectUser: notWorkProspectUser});
                            break;
                        case "aditionalMaterialRegister":
                            index = this.state.notAditionalMaterialRegister.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notAditionalMaterialRegister = this.state.notAditionalMaterialRegister
                            notAditionalMaterialRegister.splice(index, 1);
                            this.setState({ notAditionalMaterialRegister: notAditionalMaterialRegister });
                            break;
                            //AHDA 27/05/2019: Added unlockWorkPropspectUser
                        case "unlockWorkPropspect":
                        case "unlockWorkPropspectUser":
                            index = this.state.notUnlockWorkPropspect.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
                            const notUnlockWorkPropspect = this.state.notUnlockWorkPropspect
                            notUnlockWorkPropspect.splice(index, 1);
                            this.setState({ notUnlockWorkPropspect: notUnlockWorkPropspect });
                            break;
                    }
                } else {
                    //AHDA 05/04/2019
                    //Added error notification
                    this.setState({ openError: true, isLoading: false, notifType: "error", notifMsg: "Algo salió mal, intente de nuevo más tarde" });
                }
            }).catch(
                error => {
                    //AHDA 05/04/2019
                    //Added error notification 
                    this.setState({ openError: true, isLoading: false, notifType: "error", notifMsg: "Algo salió mal, intente de nuevo más tarde" });
            });
    }

    //OPEN ELEGIR LIBRETOS
    //AHDA 04/04/2019
    //Changes for recieving notification 
    handleClickOpen = (notification) => {

        //Opening dialog, setting loading and selected notification
        //AHDA 04/06/2019: Added isLoadingDialog
        this.setState({ open: true, isLoadingDialog: true, selectedNotification: notification });
        
        //Get work scripts
        apiPOST('/material/getscripts', {id_work: notification.id_obras, material_type: "libreto"}).then(
            response => {
                if(response.status === 200){
                    let auxData = response.data, auxRawData = response.data, data = []; 
                    
                    for(let i in auxData){
                        const item = [
                            getFileTitle(auxData[i].ruta), auxData[i].language, auxData[i].date_created, 'true'
                        ]
                        data.push(item); 
                    }
                    
                    //AHDA 04/06/2019: Added isLoadingDialog
                    // JCMY 10/07/2019 incidencia 1004
                    this.setState({ isLoadingDialog: false, rawScripts: auxRawData, scripts: data,notificationSelected:notification});
                }else{
                    //AHDA 04/06/2019: Added isLoadingDialog
                    this.setState({ openDialogError: true, isLoadingDialog: false, notifType: "error", notifMsg: "No se pudo obtener el listado de libretos" });
                }
            }
        ).catch(
            err => {
                //AHDA 04/06/2019: Added isLoadingDialog
                this.setState({ openDialogError: true, isLoadingDialog: false, notifType: "error", notifMsg: "No se pudo obtener el listado de libretos" });
            }
        )

    };

    //CLOSE ELEGIR LIBRETOS
    handleClose = () => {
        // JCMY 10/07/2019 inciencia 1004
        this.setState({ open: false,notificationSelected:{} });
    };

    //AHDA 05/04/2019
    //Methods for table 
    
    // --------------------------------- Script table 
    handleViewFile = (idx) => {
        let auxData = [...this.state.rawScripts]; 
        const item = auxData[idx]; 
        
 

        if (item) {
            let auxUrl = encodeURIComponent(item.ruta)
            window.open(`/file?doc=${auxUrl}`, "_blank");
        }
    }

    //AHDA 08/04/2019
    //Method for selecting sripts 
    handleClickSelectFiles = (selectedRows) => {
        const auxSelectedRows = selectedRows.data;
        const rawScripts = [...this.state.rawScripts]; 
        let auxMaterial = [];  
        let auxKeys = []; 
        
        let body = {
            id_request: this.state.selectedNotification.id_specifiedRequest,
            id_userGranted: returnCurrentUserId(),
            new_status: 1,
            granted_scripts: [], 
            screen: "notifications" //AHDA 04/06/2019
        }

        for(let x in auxSelectedRows){
            const item = rawScripts[auxSelectedRows[x].dataIndex]; 
            auxMaterial.push({id_material_multimedia: item.id_material_multimedia}); 
            auxKeys.push(item.ruta); 
        }

        body.granted_scripts = auxMaterial; 

        //AHDA 04/06/2019: Added isLoadingDialog
        this.setState({isLoadingDialog: true}); 
        
        apiPOST('/material/grantaccess', body).then(
            response => {

                if(response.status === 200){
                    //Send email to productor
                    const {selectedNotification} = this.state; 

                    //AHDA 10/04/2019
                    //Changed url 

                    //AHDA 14/05/2019
                    const url = window.location.origin; 
                    //const url = window.location.host; 
                    //console.log("Url: ", url); 

                    const bodySingleEmail = {
                        emails: [response.data.user_email],
                        type: "scriptsAccess",
                        user: {
                            name: selectedNotification.productorAcervo, 
                            last_name: ""
                        },
                        work: {
                            titulo_original: selectedNotification.titulo_original
                        },
                        portal: "acervo", 
                        //AHDA 14/05/2019
                        url: `${url}/shared-material/${selectedNotification.id_specifiedRequest ? selectedNotification.id_specifiedRequest : 0}`
                    } 

                    sendEmail(bodySingleEmail); 

                    //Send notification to roles 
                    // JCMY 10/07/2019 inciencia 1004
                    let bodyNotification = {
                        notification_type: "scriptsAccess",
                        id_work: selectedNotification.id_obras,
                        id_notification_type: 11,
                        specifiedProductor:this.state.notificationSelected.specifiedProductor
                    }

                    let bodyEmail = {
                        type: "scriptsAccess", 
                        user: {
                            name: selectedNotification.productorAcervo, 
                            last_name: ""
                        },
                        work: {
                            titulo_original: selectedNotification.titulo_original
                        },
                        url: `${url}/shared-material/${selectedNotification.id_specifiedRequest ? selectedNotification.id_specifiedRequest : 0}`
                    }

                    sendNotification(11, bodyEmail, bodyNotification);
                    
                    apiPOST("/material/shareFolder", {keyList: auxKeys, newKey: `shared/${response.data.user_email}`}).then(
                        response => {
                            //AHDA 04/06/2019: Added isLoadingDialog
                            this.setState({ isLoadingDialog: false, openDialogError: true, notifType: "success", notifMsg: "Se ha compartido el material." });
                        }
                    ).catch(err => {
                        //AHDA 04/06/2019: Added isLoadingDialog
                        this.setState({ isLoadingDialog: false, openDialogError: true, notifType: "error", notifMsg: "Ocurrió un error, intente de nuevo más tarde." });    
                        console.log("Error at sharing: ", err); 
                    })
                }else{
                    //AHDA 04/06/2019: Added isLoadingDialog
                    this.setState({ isLoadingDialog: false, openDialogError: true, notifType: "error", notifMsg: "Ocurrió un error, intente de nuevo más tarde." });
                }
            }
        ).catch(
            err => {
                this.setState({ isLoadingDialog: false, openDialogError: true, notifType: "error", notifMsg: "Ocurrió un error, intente de nuevo más tarde." });
            }
        )
    }

    //AHDA 09/04/2019
    //Method for closing messages snackbar (notifications)

    //--------------------------------- messages notifications 
    handleCloseNotification = (snack) => {
        this.setState({[snack]: false})
    } 

    //Life cycle 
    componentWillReceiveProps(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            let url = nextProps.location.search.split('=')[1];
            url = url ? Number.parseInt(url) : undefined; 
            this.setState({value: url ? url : 0});
        }
    }

    componentDidMount() {

        let config = ls.get("user_permissions").filter(x => x.menu_id === 4 )[0];
        let chk = config ? JSON.parse(config.grant_configuration) : undefined;
        
        //AHDA 05/04/2019
        //Added chkPickScripts permission to state 
        this.setState({
            chkEditNotification: true,
            chkViewNotification: true, 
            chkPickScripts: chk ? chk.chkShareMaterial : false
        });

        let url = this.props.location.search.split('=')[1];
        url = url ? Number.parseInt(url) : undefined; 
        //this.setState({value: url >= 0 ? url : 0});
        
        //Obteniendo notificaciones
        const request = {
            user_id: returnCurrentUserId(),
            portal: "acervo",
            stat: 2
        }

        this.setState({isLoading: true});
        apiPOST('/notifications/getbyuser', request).then(
            response => {
                if (response.status === 200) {

                    //AHDA 14/03/2019
                    if(response.data.length > 0){
                        const auxTabs = response.data.map(({notification_id, notification, type}) => ({notification_id, notification, type})); 
                        const notifications = response.data; 
                        let notNewWorkRegister = []
                        let notWorkUpdate = []
                        let notEvaluationAssessment = []
                        let notScriptsAccessRequest = []
                        let notScriptsAccess = []
                        let notDueTimeProspect = []
                        let notWorkAssigned = []
                        let notWorkProspectUser = []
                        let notAditionalMaterialRegister = []
                        let notUnlockWorkPropspect = []

                        for (const x in notifications) {
                            // JCMY 09/07/2019 incidencia 1059
                            if(notifications[x].notifications.length > 50){
                                // isanchez 31.07.2019 
                                notifications[x].notifications = (notifications[x].notifications).slice(0, 51);
                            }

                            switch (notifications[x].type) {
                                case "newWorkRegister":
                                    notNewWorkRegister = notifications[x].notifications;
                                    break;
                                case "workUpdate":
                                    notWorkUpdate = notifications[x].notifications;
                                    break;
                                case "evaluationAssessment":
                                    notEvaluationAssessment = notifications[x].notifications;
                                    break;
                                case "scriptsAccessRequest":
                                    notScriptsAccessRequest = notifications[x].notifications;
                                    break;
                                case "scriptsAccess":
                                    notScriptsAccess = notifications[x].notifications;
                                    break;
                                case "dueTimeProspect":
                                    notDueTimeProspect = notifications[x].notifications;
                                    break;
                                case "workAssigned":
                                    notWorkAssigned = notifications[x].notifications;
                                    break;
                                case "workProspectUser":
                                    notWorkProspectUser = notifications[x].notifications;
                                    break;
                                case "aditionalMaterialRegister":
                                    notAditionalMaterialRegister = notifications[x].notifications;
                                    break;
                                //AHDA 27/05/2019: Added unlockWorkPropspectUser
                                case "unlockWorkPropspect":
                                case "unlockWorkPropspectUser":
                                    notUnlockWorkPropspect = notifications[x].notifications;
                                    break;
                            }
                        }

                        this.setState({
                            tabs: auxTabs,
                            notNewWorkRegister: notNewWorkRegister,
                            notWorkUpdate: notWorkUpdate,
                            notEvaluationAssessment: notEvaluationAssessment,
                            notScriptsAccessRequest: notScriptsAccessRequest,
                            notScriptsAccess: notScriptsAccess,
                            notDueTimeProspect: notDueTimeProspect,
                            notWorkAssigned: notWorkAssigned,
                            notWorkProspectUser: notWorkProspectUser,
                            notAditionalMaterialRegister: notAditionalMaterialRegister,
                            notUnlockWorkPropspect: notUnlockWorkPropspect, 
                            isLoading: false, 
                            value: url ? url : notifTypes.findIndex(t => t.notification_id === auxTabs[0].notification_id)
                        })
                    }else{
                        this.setState({tabs: [], value: 0, isLoading: false}); 
                    }

                } else {
                    //AHDA 05/04/2019
                    //Added error notification 
                    this.setState({ openError: true, value: 0, isLoading: false, notifType: "error", notifMsg: "Algo salió mal, intente de nuevo más tarde" });
                }
            }).catch(
                error => {
                    //AHDA 05/04/2019
                    //Added error notification 
                    this.setState({ openError: true, value: 0, isLoading: false, notifType: "error", notifMsg: "Algo salió mal, intente de nuevo más tarde" });
            });
    }

    //AHDA 29/04/2019
    //Deleted duplicated methods 

    render() {
        const { classes, theme } = this.props;

        const returnIcon = (type) => {
           
            switch(type){
                case "newWorkRegister": 
                return <LibraryAdd />
                case "workUpdate": 
                return <Create />
                case "evaluationAssessment": 
                return <FileUpload />
                case "scriptsAccessRequest": 
                return <Block />
                case "scriptsAccess": 
                return <Check />
                case "dueTimeProspect": 
                return <Alarm />
                case "workAssigned": 
                return <LocalLibrary />
                case "workProspectUser": 
                return <LocalLibrary />
                case "aditionalMaterialRegister": 
                return <NoteAdd />
                //AHDA 27/05/2019: Added unlockWorkPropspectUser
                case "unlockWorkPropspect": 
                case "unlockWorkPropspectUser":
                return <LockOpen />
            }
        }

        return (
            <div className="marginTopLogo">
                <PapperBlock title="Notificaciones" desc="">
                    {this.state.isLoading ? <LoaderFull /> : ''}
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {
                                this.state.tabs.length > 0 ? 
                                <React.Fragment>
                                    {/* <Paper> */}
                                    <AppBar position="static" color="default">
                                        {/*AHDA 27/05/2019: Changed value -> findIndex validation*/}
                                        <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="secondary" textColor="secondary" variant="scrollable" scrollButtons="auto">
                                            {
                                                this.state.tabs.map(tab => (
                                                <Tab 
                                                icon={returnIcon(tab.type)} 
                                                label={tab.notification} 
                                                value={notifTypes.findIndex(t => t.notification_id === tab.notification_id)}
                                                key={`tab_${tab.notification_id}`}/>))
                                            }
                                        </Tabs>
                                    </AppBar>
                                    
                                    {/*AHDA 05/04/2019: Deleted test button for scripts */}

                                    <Dialog
                                        fullScreen
                                        open={this.state.open}
                                        onClose={this.handleClose}
                                        TransitionComponent={Transition}
                                        >
                                        {/*AHDA 04/06/2019: changed isLoading for isLoadingDialog*/}
                                        { this.state.isLoadingDialog ? <LoaderFull /> : ''}
                                        {/*AHDA 09/04/2019: Added Snackbar for messages notifications*/}
                                        {/*For notifications*/}
                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={this.state.openDialogError}
                                            autoHideDuration={6000}
                                            onClose={() => this.handleCloseNotification("openDialogError")}>
                                            <CustomNotification
                                                onClose={() => this.handleCloseNotification("openDialogError")}
                                                variant={this.state.notifType}
                                                message={this.state.notifMsg} />
                                        </Snackbar>

                                        <AppBar className={classes.appBar}>
                                            <Toolbar>
                                            {/* <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                                <CloseIcon />
                                            </IconButton> */}
                                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                                Elegir Libretos
                                            </Typography>
                                            <Button color="inherit" onClick={this.handleClose}>
                                                Cerrar
                                            </Button>
                                            </Toolbar>
                                        </AppBar>
                                        <div className={classes.detailContainer}>
                                            {/* <Paper className={classes.centerProgress}> */}
                                                <Grid container spacing={0}>
                                                    <Grid item md={12} sm={12} xs={12}>
                                                        {/*AHDA 05/04/2019: Loading added*/}
                                                        {/*AHDA 08/04/2019: Added clickSelect event*/}
                                                        {/*AHDA 10/04/2019: Added tableButtonText*/}
                                                        {
                                                            /*this.state.isLoadingDialog ?  
                                                            <Loader text="Obteniendo libretos"/> :
                                                            <TableElegirLibretto
                                                                info={this.state.selectedNotification}
                                                                data={this.state.scripts}
                                                                handleViewFile={this.handleViewFile}
                                                                clickSelect={this.handleClickSelectFiles}
                                                                tableButtonText={"Compartir"}
                                                            ></TableElegirLibretto>*/
                                                        }
                                                        <TableElegirLibretto
                                                            info={this.state.selectedNotification}
                                                            data={this.state.scripts}
                                                            handleViewFile={this.handleViewFile}
                                                            clickSelect={this.handleClickSelectFiles}
                                                            tableButtonText={"Compartir"}
                                                        ></TableElegirLibretto>
                                                    </Grid>
                                                </Grid>
                                            {/* </Paper> */}
                                        </div>
                                    </Dialog>
                                    
                                    {/*AHDA 05/04/2019: Added permissions, handleClickOpen method and options*/}
                                    <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={this.state.value} onChangeIndex={this.handleChangeIndex}>
                                        <TabContainer dir={theme.direction}>

                                            {
                                                this.state.notNewWorkRegister.length > 0 ?
                                                <Pagination 
                                                content={this.state.notNewWorkRegister}
                                                type="newWorkRegister"
                                                handleDeleteItem={this.handleDeleteItem}
                                                handleClickOpen={this.handleClickOpen}
                                                icon={<LibraryAdd />}
                                                canDelete={this.state.chkEditNotification}
                                                options={this.state.defaultOptions}
                                                /> :
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <Card className={classes.card}>
                                                            <CardContent className={classes.alignMsj}>
                                                                <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </TabContainer>

                                        {/*AHDA 05/04/2019: Added permissions, handleClickOpen method and options*/}
                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notWorkUpdate.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notWorkUpdate}
                                                        type="workUpdate"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        icon={<Create />}
                                                        canDelete={this.state.chkEditNotification}
                                                        options={this.state.defaultOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>

                                        {/*AHDA 05/04/2019: Added permissions, handleClickOpen method and options*/}
                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notEvaluationAssessment.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notEvaluationAssessment}
                                                        type="evaluationAssessment"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        canDelete={this.state.chkEditNotification}
                                                        icon={<FileUpload />}
                                                        options={this.state.defaultOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>

                                        {/*AHDA 04/04/2019: Added permissions*/}
                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notScriptsAccessRequest.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notScriptsAccessRequest}
                                                        type="scriptsAccessRequest"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        canDelete={this.state.chkEditNotification}
                                                        canPickScripts={this.state.chkPickScripts}
                                                        icon={<Block />}
                                                        options={this.state.scriptsOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>

                                        {/*AHDA 04/04/2019: Added permissions*/}
                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notScriptsAccess.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notScriptsAccess}
                                                        type="scriptsAccess"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        canDelete={this.state.chkEditNotification}
                                                        icon={<Check />}
                                                        options={this.state.defaultOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>

                                        {/*AHDA 04/04/2019: Added permissions*/}
                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notDueTimeProspect.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notDueTimeProspect}
                                                        type="dueTimeProspect"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        canDelete={this.state.chkEditNotification}
                                                        icon={<Alarm />}
                                                        options={this.state.defaultOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>

                                        {/*AHDA 04/04/2019: Added permissions*/}
                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notWorkAssigned.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notWorkAssigned}
                                                        type="workAssigned"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        canDelete={this.state.chkEditNotification}
                                                        icon={<LocalLibrary />}
                                                        options={this.state.defaultOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>

                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notWorkProspectUser.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notWorkProspectUser}
                                                        type="workProspectUser"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        canDelete={this.state.chkEditNotification}
                                                        icon={<LocalLibrary />}
                                                        options={this.state.defaultOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>

                                        {/*AHDA 04/04/2019: Added permissions*/}
                                        <TabContainer dir={theme.direction}>
                                            {
                                                this.state.notAditionalMaterialRegister.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notAditionalMaterialRegister}
                                                        type="aditionalMaterialRegister"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        canDelete={this.state.chkEditNotification}
                                                        icon={<NoteAdd />}
                                                        options={this.state.defaultOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>
                                        {
                                            this.state.notUnlockWorkPropspect.length
                                        }
                                        <TabContainer dir={theme.direction} >
                                            {
                                                this.state.notUnlockWorkPropspect.length > 0 ?
                                                    <Pagination
                                                        content={this.state.notUnlockWorkPropspect}
                                                        type="unlockWorkPropspect"
                                                        handleDeleteItem={this.handleDeleteItem}
                                                        handleClickOpen={this.handleClickOpen}
                                                        canDelete={this.state.chkEditNotification}
                                                        icon={<LockOpen />}
                                                        options={this.state.defaultOptions}
                                                    /> :
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Card className={classes.card}>
                                                                <CardContent className={classes.alignMsj}>
                                                                    <Typography className={classes.textMsj}>Sin notificaciones</Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </TabContainer>
                                    </SwipeableViews>
                            </React.Fragment> : 
                            <React.Fragment>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={12}>
                                        <Card className={classes.card}>
                                            <CardContent className={classes.alignMsj}>
                                                <Typography className={classes.textMsj}>No se han configurado notificaciones</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            }
                        </Grid>
                    </Grid>

                    {/*AHDA 05/04/2019: Added Snackbar for error notifications*/}
                    {/*AHDA 09/04/2019: Changed method to specify snack*/}
                    {/*For notifications*/}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.openError}
                        autoHideDuration={6000}
                        onClose={() => this.handleCloseNotification("openError")}>
                        <CustomNotification
                            onClose={() => this.handleCloseNotification("openError")}
                            variant={this.state.notifType}
                            message={this.state.notifMsg} />
                    </Snackbar>
                </PapperBlock>
            </div>
        );
    }
}

Notifications.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(Notifications));