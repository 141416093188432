import React from 'react';
import { object, PropTypes } from 'prop-types';
import { renderRoutes } from 'react-router-config';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import { checkSession } from '../../services/cognito_service'; 

class OuterEmpty extends React.Component {

  UNSAFE_componentWillMount = () =>{
    //AHDA 12/04/2019
    //Passed params to checkSession method
    if(this.props.location.search !== ""){
      let auxUrl = this.props.location.pathname + this.props.location.search; 
      checkSession(auxUrl);
    }else{
      checkSession(this.props.location.pathname);
    } 
  }
  
  render() {
    const {
      classes,
      route,
    } = this.props;
    return (
      <div className={classes.appFrameOuter}>
        <main className={classes.outerEmptyContent} id="mainContent">
            <div className={classes.outerEmptyContent}>
            {renderRoutes(route.routes)}
            </div>
        </main>
      </div>
    );
  }
}

OuterEmpty.propTypes = {
  classes: PropTypes.object.isRequired,
  route: object,
};

OuterEmpty.defaultProps = {
  route: object
};

export default (withStyles(styles)(OuterEmpty));