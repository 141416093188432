import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FloatingPanel from './FloatingPanel';

import styles from '../../../../components/Catalogue/formFlot-jss';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm} from 'react-material-ui-form-validator';


class AddCatalogue extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: []
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    let oldFiles = this.state.files;
    const filesLimit = 2;
    oldFiles = oldFiles.concat(files);
    if (oldFiles.length > filesLimit) {
      console.log('Cannot upload more than ' + filesLimit + ' items.');
    } else {
      this.setState({ img: files[0].preview || '/pic' + files[0].path });
    }
  }

  sendValues = (values) => {
    setTimeout(() => {
      this.props.submit(values, this.state.img);
      this.setState({ img: '' });
    }, 500);
  }

  render() {
    const {
      classes,
      openForm,
      closeForm,
      submit, 
      title,
      clearForm
    } = this.props;

    const branch = '';
    return (
        <FloatingPanel title={title} openForm={openForm} branch={branch} closeForm={closeForm}>

        <ValidatorForm onSubmit={submit}>
          <section className={classes.bodyFormFlot}>
           
            {this.props.children}
            
          </section>
          <div className={classes.buttonArea}>
            <Button variant="contained" color="primary" type="submit" >Guardar</Button>
            <Button type="button" onClick={clearForm}>Limpiar</Button>
          </div>
        </ValidatorForm>

        </FloatingPanel>
    );
  }
}

AddCatalogue.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  clearForm: PropTypes.func
};

export default  withStyles(styles) (AddCatalogue);
