import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
//import Tooltip from '@material-ui/core/Tooltip';
//import Button from '@material-ui/core/Button';
//import Add from '@material-ui/icons/Add';
import AddProfileForm from './AddProfileForm';
import FloatingPanel from '../../../../components/Panel/FloatingPanel';
import './profile-jss';

class AddProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      img: '',
      files: []
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    let oldFiles = this.state.files;
    const filesLimit = 2;
    oldFiles = oldFiles.concat(files);
    if (oldFiles.length > filesLimit) {
      console.log('Cannot upload more than ' + filesLimit + ' items.');
    } else {
      this.setState({ img: files[0].preview || '/pic' + files[0].path });
    }
  }

  sendValues = (values) => {
    setTimeout(() => {
      this.props.submit(values, this.state.img);
      this.setState({ img: '' });
    }, 500);
  }

  render() {
    const {
      //classes,
      openForm,
      closeForm,
      avatarInit,
      //addContact,
      dataInit,
      submit,
      actionProfile
    } = this.props;
    const { img } = this.state;
    const branch = '';
    return (
      <div>
        <FloatingPanel title={`${actionProfile} Perfil`} openForm={openForm} branch={branch} closeForm={closeForm}>
          <AddProfileForm
            onSubmit={()=>{}}
            onDrop={this.onDrop}
            imgAvatar={img === '' ? avatarInit : img}
            handleSubmit={(event, data)=>submit(event, data)}
            dataInit={dataInit}
            isOpen={openForm}
          />
        </FloatingPanel>
      </div>
    );
  }
}

AddProfile.propTypes = {
  classes: PropTypes.object,
  submit: PropTypes.func,
  addContact: PropTypes.func,
  openForm: PropTypes.bool,
  avatarInit: PropTypes.string,
  closeForm: PropTypes.func,
  dataInit:PropTypes.object
};

export default AddProfile;
