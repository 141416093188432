import {
  Checkbox,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import iconPlay from '../../../static/images/iconplay.png';
import { getFileTitle } from '../../helpers/general';
import { LanguageConsumer } from '../../language/LanguageContext';
import { styles } from './styles';

class MaterialVideos extends React.Component {
  state = {
    openModal: false,
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    const { dataProduct, classes, handleOpenModal, catalogName, handleDownload } = this.props;

    const settings = {
      dots: false,
      infinite: false,
      centerMode: false,
      speed: 500,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      cssEase: 'ease-out',
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 959,
          settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 599,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const parseUrl = (url) => {
      let auxUrl = url.split('/');
      auxUrl = auxUrl.length > 0 ? auxUrl[auxUrl.length - 1].split('.') : '';
      return auxUrl !== '' ? auxUrl[0].replace('+', ' ') : '';
    };

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className={classNames(classes.root, 'detalleSlider')}>
              {/* <Slider {...settings}> */}
              {dataProduct.map((product) => {
                product.video_config = product.video_config ? typeof product.video_config === 'object' ? product.video_config : JSON.parse(product.video_config) : {};
                if (product.type === 'video') {
                  if (product === -1) {
                    return false;
                  }

                  return (
                    <>
                      <Grid container spacing={16}>
                        <Grid item xs={12} sm={3}>
                          <div
                            className={classes.videoContainer}
                            onClick={() => handleOpenModal(product)}
                          >
                            <img className={classes.videoImg} src={iconPlay} />
                          </div>

                          <div className={classes.alignCenter}>
                            {/* <Typography variant="body1" className={classes.videoName}>
                              <small>{getFileTitle(product.url)}</small>
                            </Typography> */}
                            <Typography variant="body2">
                              <small>({product.descripcion})</small>
                            </Typography>
                            <IconButton onClick={() => handleDownload(product.url)}>
                              <CloudDownload />
                            </IconButton>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Grid container spacing={16}>
                            <Grid item xs={12} sm={12}>
                              <FormControl fullWidth>
                                <InputLabel shrink>{lang.adminWork.material_description}</InputLabel>
                                <label className={classes.paddingTopLabel}>{product.description}</label>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                className={classes.formControlSpacing}
                              >
                                <InputLabel shrink>{lang.adminWork.material_amount}</InputLabel>
                                <label className={classes.paddingTopLabel}>{product.amount}</label>
                              </FormControl>
                              <FormControl
                                fullWidth
                                className={classes.formControlSpacing}
                              >
                                <InputLabel shrink>{catalogName}</InputLabel>
                                {product.video_config && <label className={classes.paddingTopLabel}>{product.video_config["duration"]}</label>}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div
                                className={classNames(
                                  classes.flexboxInputs,
                                  classes.formControlSpacing,
                                )}
                              >
                                <Typography
                                  variant="body1"
                                  component="span"
                                  className={classes.checkboxLabel}
                                >
                                  {lang.adminWork.material_audio}
                                </Typography>
                                <Checkbox
                                  checked={product.video_config["audio"] || false}
                                  disabled
                                />
                                <FormControl className={classes.flexGrow}>
                                  <InputLabel shrink>{lang.adminWork.material_lang}</InputLabel>
                                  <label className={classes.paddingTopLabel}>{product.video_config["audioLang"]}</label>
                                </FormControl>
                              </div>
                              <div
                                className={classNames(
                                  classes.flexboxInputs,
                                  classes.formControlSpacing,
                                )}
                              >
                                <Typography
                                  variant="body1"
                                  component="span"
                                  className={classes.checkboxLabel}
                                >
                                  {lang.adminWork.material_subtitle}
                                </Typography>
                                <Checkbox
                                  checked={product.video_config["subtitle"] || false}
                                  disabled
                                />
                                <FormControl className={classes.flexGrow}>
                                  <InputLabel shrink>{lang.adminWork.material_lang}</InputLabel>
                                  <label className={classes.paddingTopLabel}>{product.video_config["subLang"]}</label>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );

                  // return (
                  //   <Grid
                  //     container
                  //     alignItems="flex-start"
                  //     justify="flex-start"
                  //     direction="row"
                  //     spacing={8}
                  //     key={`video${index}`}>
                  //       <Grid item xs={12} className={classes.videos}>
                  //           {/*FALTA BOTON PARA PLAY*/}
                  //           <div className={classes.videoContainer}>
                  //             <img className={classes.videoImg} src={iconPlay} alt="video 1" onClick={() => handleOpenModal(product)}/>
                  //           </div>
                  //           <p className={classes.text}><small>{getFileTitle(product.url)}</small></p>
                  //           {/*AHDA 03/06/2019: Added language description*/}
                  //           <p className={classes.text}><small>({product.descripcion})</small></p>
                  //       </Grid>
                  //   </Grid>
                  // );
                }
                return null;
              })}

              {/* </Slider>  */}
            </div>
        }
      </LanguageConsumer>
    );
  }
}

MaterialVideos.propTypes = {
  classes: PropTypes.object.isRequired,
  dataProduct: PropTypes.array,
  //showDetail: PropTypes.func.isRequired
};

export default withStyles(styles)(MaterialVideos);
