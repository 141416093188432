import { withOAuth } from 'aws-amplify-react';
import React from 'react';
import * as uuid from 'uuid/v1';
import { awscognito, oauth } from '../../../config/config';
import { Button } from '../../atoms';

class OAuthButton extends React.Component {
  componentWillMount() {
    console.log(uuid());
  }
  
  handleActiveDirectory() {
    window.location.href = 'https://' + oauth.domain + '/oauth2/authorize?identity_provider='+oauth.identityProvider+'&redirect_uri=' + process.env.REACT_APP_host + '&response_type=TOKEN&client_id=' + awscognito.userPoolWebClientId + '&state=' + uuid() + '&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile';
  }
  
  render() {
    return (
      <div className="oauthbutton__container">
        <Button block onClick={this.handleActiveDirectory}>
          Iniciar sesión aquí
        </Button>
      </div>
    )
  }
}

export default withOAuth(OAuthButton);