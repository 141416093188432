import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { object, PropTypes } from 'prop-types';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
//import  BreadCrumb from '../BreadCrumb/BreadCrumb'; 

import styles from './styles';
//import Hidden from '@material-ui/core/Hidden';
import './estilos.css';

class Dashboard extends React.Component {
  state = {
    transform: 0,
    sidebarOpen: true,
    pageLoaded: false
  };

  //AHDA 17/05/2019
  //Commented Method 
  /*_isMounted = false; 

  componentWillMount = () =>{
    if(!this._isMounted){
      //checkSession();
      this._isMounted = true; 
    } 
  }*/

  componentDidMount = () => {
    // Scroll content to top
    const mainContent = document.getElementById('mainContent');
    //mainContent.addEventListener('scroll', this.handleScroll);

    // Set expanded sidebar menu
    //const currentPath = this.props.history.location.pathname;
    //this.props.initialOpen(currentPath);

    // Play page transition
    this.loadTransition(true);

    // Execute all arguments when page changes
    /*this.unlisten = this.props.history.listen(() => {
      mainContent.scrollTop = 0; 
      setTimeout(() => {
        this.loadTransition(true);
      }, 500);
    });*/
  }

  /*componentWillUnmount() {
    const mainContent = document.getElementById('mainContent');
    mainContent.removeEventListener('scroll', this.handleScroll);
  }*/

  loadTransition = () => {
    this.setState({ pageLoaded: true });
  }

  handleScroll = (event) => {
    const scoll = event.target.scrollTop;
    this.setState({
      transform: scoll
    });
  }

  toggleDrawer = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  render() {
    const {
      classes,
      route,
    } = this.props;
    const darker = true;
    const { sidebarOpen, pageLoaded } = this.state;

    return (
      <div className={classes.appFrameInner}>
        <Header toggleDrawerOpen={this.toggleDrawer} turnDarker={this.state.transform > 30 && darker} margin={sidebarOpen} />
        <Sidebar
          open={sidebarOpen}
          toggleDrawerOpen={this.toggleDrawer}
          loadTransition={this.loadTransition}
          turnDarker={this.state.transform > 30 && darker}
        />
        <main className={classNames(classes.content, !sidebarOpen && classes.contentPadding)} id="mainContent">
          <div className={classes.bgbar}>
          </div>
          <section className={classNames(classes.mainWrap)}>
            <Fade
              in={pageLoaded}
              mountOnEnter
              unmountOnExit
              {...(pageLoaded ? { timeout: 700 } : {})}
            >
              <div className={!pageLoaded ? classes.hideApp : ''}>
                {renderRoutes(route.routes)}
              </div>
            </Fade>
          </section>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  route: object
};

Dashboard.defaultProps = {
  route: object
};

export default withStyles(styles)(Dashboard);
