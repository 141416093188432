import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import styles from './../reportes-jss'
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';

//Filtros
import { renderInput, getSuggestions, renderSuggestion } from './../autocomplete'
import Downshift from 'downshift';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import Loader from '../../../../components/Loader/Loader';
import Grid from '@material-ui/core/Grid';

import { textLabelsEs } from '../../../../utils/tableTexts';

import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';

import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

class Prospectos extends React.Component {
  state = {
    //Filters
    productor: 0,
    tituloObra: '',
    //Table
    columns: [],
    data: [],
    //Catalogos
    producers: [],
    works: [],
    results: 0,
    isLoading: true
  }

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ tituloObra: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ tituloObra: changes.inputValue })
    }
  }

  handleClearFilters = () => {
    this.setState({
      productor: 0,
      tituloObra: '',
      isLoading: true
    });

    const request = {
      idProducer: 0,
      title: "0"
    }
    this.handleFetch(request);
  }

  //Download csv
  handleDownload = (data) => {
    const {lang} = this.context;
    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'acervo',
      screen: 'reporteProspectos'
    }
    apiPOST('/audit/add', body);


    const dataCsv = [[
      lang.reports.title, lang.reports.producer, lang.reports.assigmentDate, lang.reports.dueDate,
      lang.reports.timeRemaining, lang.reports.favoriteVotes, lang.reports.englishTitle, lang.reports.otherTitle
    ]]

    for (const x in data) {
      const object = []
      object.push(data[x])
      //object.unshift(" ");
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "Prospectos .csv";
    download.click();

  }

  //JCMY 22/01/19
  //Llamada de busqueda
  searchs = () => {
    this.setState({ isLoading: true })
    const request = {
      idProducer: this.state.productor,
      title: this.state.tituloObra === "" ? "0" : this.state.tituloObra === "TODOS" ? "0" : this.state.tituloObra
    }

    this.handleFetch(request);
  }

  componentDidMount() {

    const { lang } = this.context;
    this.setState({
      columns: [
        lang.reports.title, lang.reports.producer, lang.reports.assigmentDate,
        lang.reports.englishTitle, lang.reports.otherTitle, lang.reports.comments
      ]
    })

    //Catalogo productores
    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 17 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ producers: response.data })
        }
        else {
          this.setState({ openError: true });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false });
        });

    //Catalogo obras
    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 16 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ works: response.data })
        } else {
          this.setState({ openError: true });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false });
        });

    //Carga inicial
    const request = {
      idProducer: 0,
      title: "0"
    }
    this.handleFetch(request);
  }

  handleFetch = (request) => {
    apiPOST('/reports/getByProspects', request).then(response => {
      if (response.status === 200) {
        const result = [];
        for (const x in response.data) {
          const obj = [];
          const auxObj = response.data[x]; 
          obj.push(auxObj.title ? auxObj.title : "-");
          obj.push(auxObj.user ? auxObj.user : "-");
          obj.push(auxObj.assigment_date ? auxObj.assigment_date : "-");
          obj.push(auxObj.english_title ? auxObj.english_title : "-");
          obj.push(auxObj.other_title ? auxObj.other_title : "-");
          obj.push(auxObj.comments || "-");
          result.push(obj);
        }
        this.setState({ data: result, isLoading: false, results: response.data.length })
      } else {
        this.setState({ openError: true, isLoading: false });
      }
    }).catch(error => {
      this.setState({ openError: true, isLoading: false });
    });
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    //AHDA 29/04/2019
    //Changed filterType
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.download} placement="bottom">
                <IconButton aria-label="download" onClick={() => this.handleDownload(this.state.data)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>
      },
      customSearch: (searchQuery, currentRow) => {
        let isFound = false;
        const str = searchQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        currentRow.forEach(col => {
          if (col && col.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(str) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      }
    };
    return (
      <div className="marginTopLogo">
        <LanguageConsumer>
          {
            ({ lang }) =>
              <PapperBlock title={lang.reports.reports} whiteBg desc="">
                {/* Inicio Filtros */}
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.productor} onChange={this.handleChange} name="productor" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.producers.map((producer, idx) =>
                              (
                                <MenuItem value={producer.value} key={`producer${idx}`}>{producer.data}</MenuItem>
                              ))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.producer}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.positionRelative}>
                      <div className={classes.root}>
                        <Downshift selectedItem={this.state.tituloObra} onStateChange={this.handleStateChange}>
                          {({
                            getInputProps,
                            getItemProps,
                            isOpen,
                            inputValue,
                            selectedItem,
                            highlightedIndex
                          }) => (
                              <div className={classes.formControl}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  // helperText: lang.reports.company,
                                  helperText: lang.reports.title,
                                  InputProps: getInputProps({
                                    // placeholder: lang.reports.company,
                                    placeholder: lang.reports.title,
                                    id: 'titulo',
                                    name: 'titulo',
                                  }),
                                })}
                                {isOpen ? (
                                  <Paper className={classNames(classes.paper, classes.list)} square>
                                    {getSuggestions(inputValue, this.state.works).map((suggestion, index) =>
                                      renderSuggestion({
                                        suggestion,
                                        index,
                                        itemProps: getItemProps({ item: suggestion.label }),
                                        highlightedIndex,
                                        selectedItem,
                                      }),
                                    )}
                                  </Paper>
                                ) : null}
                              </div>
                            )}
                        </Downshift>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={12}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.searchs.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                  </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}
                <div className={classes.bitacoraMov}>
                  {
                    this.state.isLoading ?
                      <Loader text={"Cargando"} /> :
                      <MUIDataTable title={`${lang.reports.prospects} (${this.state.results} ${lang.reports.results})`} data={data} columns={columns} options={options} />
                  }
                </div>
              </PapperBlock>
          }
        </LanguageConsumer>
      </div>
    );
  }
}

Prospectos.contextType = LanguageCxt;

export default withStyles(styles)(Prospectos);