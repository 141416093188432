const materialTypes = [
    {
        value: 'libreto', 
        data: 'Libretos'
    }, 
    {
        value: 'escaleta', 
        data: 'Mapa de ruta (Macro Escaleta)'
    },
    {
        value: 'capitulo1', 
        data: 'Capítulo 1'
    },
    {
        value: 'biblia', 
        data: 'Biblia'
    },
    {
        value: 'trailer', 
        data: 'Traíler'
    },
    {
        value: 'video', 
        data: 'Episodios (videos)'
    },
    {
        value: 'brochure', 
        data: 'Brochure'
    },
    {
        value: 'img', 
        data: 'Imágenes'
    },
    {
        value: 'dialogo', 
        data: 'Diálogo'
    }
]

export default materialTypes; 