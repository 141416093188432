import { assign } from 'lodash';
import LocalizedStrings from 'react-localization'; 

let langstr =  new LocalizedStrings({
    en : {
        login: {
            login: 'Login', 
            logginIn: 'Logging in...', 
            incorrectUser: 'Incorrect username or password', 
            remember: 'Remember',
            cantLogin: "Can't login?", 
            forgotPassword: 'FORGOT PASSWORD', 
            register: 'REGISTER', 
            changingPassword : 'Changing password', 
            currentPwd : 'Current Password',
            newPwd: 'New Password', 
            confirmPassword : 'Confirm Password', 
            passNoMatch : "Passwords don't match", 
            changeRequired : "Change temporary password", 
            pwdPolicy: "Password must contain: numbers, special characters, uppercase and lowercase letters"
        }, 
        common : {
            errMsg : 'Something went wrong, try again later', 
            errProspects: 'Sólo puedes tener como máximo 3 obras marcadas como Prospecto',  
            errBlockedUser: 'Se te ha bloqueado el acceso al portal', 
            email: 'Email', 
            password: 'Password', 
            continue: 'Continue', 
            youAreHere: "You are here:", 
            favorites: "Favoritos", 
            bloqued: "Bloqueado", 
            selected: "Seleccionado", 
            add: "Agregar",
            watchMore: "VER MÁS", 
            goToDashboard: "Ir a Acervo", 
            year: "Año", 
            contact: "CONTACTO", 
            profile: "Perfil", 
            logout: "Cerrar Sesión", 
            loading: "Cargando", 
            cancel:"Cancelar", 
            clear: "Limpiar",
            delete: "Eliminar",
            ok: "Aceptar",
            save: "Guardar",
            edit: "Editar", 
            errEmptySearch: "Ingresa un criterio de búsqueda", //AHDA 14/05/2019
            noInfo: "No hay información disponible",
            serviceError:"Ocurrió un error, intenta más tarde"
        }, 
        notifications: {
            empty: "Bandeja de notificaciones vacía"
        },
        menu: {
            acervo: "Acervo", 
            profile: "Mi Perfil", 
            reports: "Reports",
            favoritos: "Favoritos",
            prospectos: "Prospectos",
            proyectos: "Proyectos",
            composicion_acervo: "Composición Acervo Literario",
            bitacora_movimientos: "Bitácora de movimientos",
            perfiles_privilegios: "Perfiles y Privilegios", 
            obras_literarias: "Obras Literarias",
            consultas: "Obras más consultadas", 
            settings: "Configuración",  
            catalogos: "Catálogos",
            pais_de_origen: "País de Origen",
            fuente: "Fuente",
            tematica: "Tématica",
            audiencia: "Audiencia",
            ambiente: "Ambiente",
            productores: "Productores",
            idioma: "Idioma",
            generos: "Géneros",
            formato: "Formato",
            motivacion: "Motivación",
            vigencia: "Vigencia",
            autores_adaptadores: "Autores/Adaptadores",
            duracion_libretos: "Duración de Libretos",
            subgenero: "Subgénero",
            ventana: "Ventana",
            temporalidad: "Temporalidad",
            tipo_contrato: "Tipo de contrato",
            empresa_proveedores: "Empresas/Proveedores",
            notifications: "Notificaciones",
            ConfigureNotifications: "Configuración de notificaciones",
            users: "Usuarios",
            usuarios: "Usuarios",
            parameters: "Parameters",
            controlmateriales: "Control de Materiales"
        }, 
        markedWorks:{
            favorites: "Favoritos", 
            prospectAvailable:"Disponible como prospecto", 
            prospect: "Prospecto", 
            inReviewByOterUser: "Esta obra está en revisión por otro usuario", 
            projectAvailable: "Disponible para Proyecto", 
            project:"En proyecto", 
            projectAssigned: "Esta obra ya está asignada a un usuario"
        }, 
        lang: {
            es: "Español", 
            en: "Inglés", 
            pt: "Portugués", 
            de: "Alemán", 
            ja:"Japonés",
            el:"Griego", 
            tr: "Turco"
        }, 
        //AHDA 29/04/2019
        //Order by value
        actionTypesCatalogue: {
            workUpdateStatus: "Actualización de obra",
            userUpdateStatus: "Actualización de usuario", 
            workUpdateValidity: "Actualización de vigencia de obra",
            extendTerm: "Ampliar plazo",
            asignProject: "Asignar proyecto",
            advance: "Avanzar",
            deleteScripts: "Borrar libretos",
            searchWork: "Búsqueda de obra",
            changeToVideo: "Cambio de material en vídeo",
            shareMaterial: "Compartir material", 
            unlockProject: "Liberar obra",
            edit: "Edición",
            delete: "Eliminación",
            exportCSV: "Exportar reporte",
            finishProject: "Finalizar proyecto",
            login: "Inicio de sesión",
            markAsFavorite: "Marcar como favorito",
            markAsProspect: "Marcar como prospecto",
            register: "Registro de obra",
            requestMaterial: "Solicitar material", 
        }, 
        userStatus:{
            0: 'Inactivo',
            1: 'Activo'
        },
        screens: {
            acervoComposition: "Reporte Composición Acervo Literario", 
            home: "Acervo", 
            login: "Inicio de sesión", 
            notDefined: "-", 
            reporteConsultas: "Reporte Obras más consultadas", 
            search: "Búsqueda general", 
            searchCategory: "Búsqueda por categoría", 
            viewDetail: "Vista detalle", 
            reporteBitacoraMovimientos: "Reporte Bitácora de movimientos", 
            reporteComposicionAcervo: "Reporte Composición de ACERVO literario", 
            reporteProspectos: "Reporte Prospectos", 
            reporteProyectos: "Reporte Proyectos", 
            notificationsPickScript: "Notificaciones - Elegir libretos", 
            configurationUsers: "Configuración de Usuarios", //AHDA 09/05/2019
            users: "Configuración de Usuarios", //AHDA 09/05/2019
            confLiteraryWorks: "Configuración de Obras Literarias" , //AHDA 09/05/2019
            reporteFavoritos: "Reporte Favoritos", //AHDA 09/05/2019
            reporteObrasLiterarias: "Reporte Obras Literarias", //AHDA 09/05/2019
            notifications: "Notificaciones", //AHDA 04/06/2019,
            admin: "Administración de obras"
        }, 
        reports:{
            repAcervoComposition: "Composición Acervo Literario",
            repMostSearchedWorks: "Obras más consultadas",
            reports: "Reportes",
            results: "resultados", 
            download: "Descargar", 
            print: "Imprimir", 
            compProv: "Empresa/Proveedor", 
            title: "Título de la Obra", 
            authors: "Autor(es)/Nickname", 
            country: "País", 
            registerDate: "Fecha de registro", 
            genre: "Género", 
            status: "Estatus", 
            playType: "Tipo Obra", 
            primal: "Primigenia", 
            adaptation: "Adaptación",
            sales: "Ventas", 
            production: "Producción",
            all: "Todos", 
            playEstatus: "Estatus de la Obra", 
            materialType: "Tipo de material", 
            contractType: "Tipo de Contrato", 
            startDate: "Fecha Inicio", 
            endDate: "Fecha Final", 
            provider: "Proveedor", 
            date: "Fecha", 
            user: "Usuario", 
            action: "Acción", 
            description: "Descripción",
            repMovementLog: "Bitácora de movimientos", 
            author: 'Autor/Nickname', 
            validity: "Vigencia", 
            purchaseDate: "Fecha de compra", 
            currency: "Moneda",  
            produced: "Producidas", 
            producer: "Productor", 
            format: "Formato", 
            source: "Fuente",
            productionYear: "Año de producción", 
            destiny: "Destino", 
            platform: "Ventana", 
            chapterNumber: "Capítulos", 
            audience: "Audiencia", 
            yes: "Sí", 
            totalSearchs: "Total de consultas", 
            favorites: "Favoritos", 
            assigmentDate: "Fecha de Asignación", 
            dueDate: "Fecha Límite", 
            timeRemaining: "Tiempo Restante (días)", 
            favoriteVotes: "Votos de Favoritos", 
            prospects: "Prospectos", 
            projects: "Proyectos",
            employee:"Número de empleado",
            observation:"Observación",
            usuarios: 'Usuarios',
            name: 'Nombre',
            lastname: 'Apellidos',
            email: 'Correo',
            profile: 'Perfil',
            status: 'Estatus',
            userType: 'Tipo de usuario',
            lastAccess: 'Fecha y hora de último ingreso al portal',
            controlMateriales: 'Control de materiales',
            obraId: 'Id de la obra',
            obraNombre: 'Nombre de la obra',
            obraProductor: 'Productor',
            obraGenero: 'Género',
            obraFechaEntrega: 'Fecha de entrega del material',
            obraTipoMaterial: 'Tipo de material',
            obraObservaciones: 'Observaciones',
            obraObra: 'Obra',
            obraAddComment: "Agregar observación",
            englishTitle: "Título en Inglés",
            otherTitle: "Título en otro idioma",
            comments:"Comentarios"
        }, 
        treeTable: {
            rowsPerPage: "Rows per page"
        },
        adminWork:{
            addWork: "Registro de Obra Literaria",
            updateWork: "Actualización de Obra Literaria",
            addMaterial: "Agregar Material",
            material_type: "Tipo de Material",
            material_description: "Descripción",
            material_amount: "Cantidad",
            material_audio: "Audio",
            material_subtitle: "Subtítulo",
            material_lang: "Idioma",
            material_missing: "Faltantes",
            dropFile: "Arrastra tu archivo o da click aquí",
            noDataMaterials: "No se ha cargado ningún material",
            noData: "No hay datos disponibles",
            additionalInfo: "Material - Información adicional",
            dropFileLimit: "Total de archivos por carga ",
            dropFileLimitWarning: "Límite de archivos por carga superado",
            prospecteUpdated:"Prospecto actualizado correctamente",
            missingProducer:`Debe agregar un productor en el campo vacío`,
            projectUpdated:"Proyecto actualizado correctamente",
            requiredFields: "Completa los campos obligatorios"
        }, 
        playDetail:{
            originalWorkTitle: "Título de la obra primigenia:", 
            otherVersions: "Otras versiones", 
            authors: "Autor(es)", 
            adapters: "Adaptador(es)", 
            productors: "Productor(es)", 
            format: "Formato",
            platform: "Ventana", 
            audience: "Audiencia", 
            schedule: "Horario",
            suggested_schedule: "Horarios sugeridos",
            genre: "Género", 
            subgenre: "Subgénero", 
            theme: "Temática", 
            motivation: "Motivación", 
            temporality: "Temporalidad", 
            ambient: "Ambiente", 
            synopsis: "Sinopsis", 
            characters: "Personajes", 
            evaluation: "Evaluación", 
            material :"Material", 
            moreInfo: "Más Información", 
            validity: "Vigencias", 
            literaryTeams: "Equipos Literarios", 
            shortSynopsis: "Sinopsis Corta", 
            longSynopsis: "Sinopsis Larga", 
            relationshipDiagram: "Diagrama de relaciones",
            name: "NOMBRE", 
            age: "EDAD", 
            characterPsychology:"PSICOLOGÍA DEL PERSONAJE",
            calification: "Calificación",
            rating: "Rating", 
            share: "Share", 
            comentaries: "Comentarios",
            requestScripts: "Solicitar libretos", 
            scripts: "Libretos", 
            spanishScripts: "Libretos Español",
            videos: "Videos",
            images: "Imágenes",
            others: "Otros", 
            bible: "Biblia",
            brochure: "Brochure", 
            escaleta: "Escaleta", 
            more: "Más",
            copyRights: "DERECHOS", 
            country: "País", 
            language: "Idioma", 
            validity: "VIGENCIAS", 
            royalties: "REGALIAS", 
            purchaseYear: "AÑO DE COMPRA", 
            proudctionYear:"Año de producción",
            chapters: "Libretos", 
            airedChapters: "Capítulos al aire",  
            relatdWorks: "Obras Relacionadas", 
            //This comes from DB -- DO NOT CHANGE
            ventas: "Ventas", 
            produccion: "Producción",
            adptation: "Adaptación", 
            original:"Primigenia",
            vigente: "Vigente",
            vencida: "Vencida",
            porVencer: "Por vencer",
            produccionTelevisa: "Producción Cloudgenia",
            produccionExtranjera: "Producción Extranjera",
            noProducida: "No producida",
            inedita: "Inédita",
            unlockWork:"Liberar Obra",
            downloadResume:"Descargar Ficha Técnica"
        },
        tabMoreInfo:{
            title: "Asignación de obra",
            assignedto:"Proyecto asignado a:",
            assignedUser:"Prospecto asignado a:",
            assignedDate:"Fecha en la que se asignó como prospecto:",
            comments: "Comentarios:",
            assignWork:"Asignar obra",
            unlockWork: "Desbloquear obra",
            finishWork: "Finalizar proyecto",
            commentsPlaceholder:"Escriba sus comentarios"
        }
    }, 
    es : {
        login: {
            login: 'Iniciar sesión', 
            logginIn: 'Iniciando sesión...', 
            incorrectUser: 'Usuario o contraseña incorrecto', 
            remember: 'Recordar',
            cantLogin: "¿No puedes entrar? ", 
            forgotPassword: 'OLVIDÉ MI CLAVE', 
            register: 'REGISTRARME', 
            changingPassword : 'Cambiando contraseña...', 
            currentPwd : 'Contraseña Temporal',
            newPwd: 'Nueva Contraseña', 
            confirmPassword : 'Confirmar Contraseña', 
            passNoMatch : "Las contraseñas no coinciden", 
            changeRequired : "Cambiar contraseña temporal", 
            pwdPolicy: "La contraseña debe tener: números, caracteres especiales, letras mayúsculas y minúsculas"
        }, 
        common : {
            errMsg : 'Algo salió mal, intenta de nuevo más tarde', 
            errProspects: 'Sólo puedes tener como máximo 3 obras marcadas como Prospecto', 
            errBlockedUser: 'Se te ha bloqueado el acceso al portal', 
            email: 'Correo electrónico', 
            password: 'Contraseña', 
            continue: 'Continuar', 
            youAreHere: "Estás aquí:", 
            favorites: "Favoritos", 
            bloqued: "Bloqueado", 
            selected: "Seleccionado", 
            add: "Agregar", 
            watchMore: "VER MÁS", 
            goToDashboard: "Ir a Acervo", 
            year: "Año", 
            contact: "CONTACTO", 
            profile: "Perfil", 
            logout: "Cerrar Sesión", 
            loading: "Cargando", 
            cancel:"Cancelar", 
            clear: "Limpiar", 
            errEmptySearch: "Ingresa un criterio de búsqueda", //AHDA 14/05/2019
            noInfo: "No hay información disponible",
            serviceError:"Ocurrió un error, intenta más tarde"
        }, 
        notifications: {
            empty: "Bandeja de notificaciones vacía"
        },
        menu: {
            acervo: "Acervo", 
            profile: "Mi Perfil", 
            reports: "Reportes",
            favoritos: "Favoritos",
            prospectos: "Prospectos",
            proyectos: "Proyectos",
            horarios_sugeridos: "Horarios sugeridos",
            territorios_restringidos: "Territorios Restringidos",
            composicion_acervo: "Composición Acervo Literario",
            bitacora_movimientos: "Bitácora de movimientos", 
            perfiles_privilegios: "Perfiles y Privilegios",
            obras_literarias: "Obras Literarias",
            consultas: "Obras más consultadas", 
            settings: "Configuración", 
            catalogos: "Catálogos",
            pais_de_origen: "País de Origen",
            fuente: "Fuente",
            tematica: "Tématica",
            audiencia: "Audiencia",
            ambiente: "Ambiente",
            productores: "Productores",
            idioma: "Idioma",
            generos: "Géneros",
            formato: "Formato",
            motivacion: "Motivación",
            vigencia: "Vigencia",
            autores_adaptadores: "Autores/Adaptadores3",
            //GEO 11/04/2019
            //Se cambia texto
            duracion_libretos: "Duración de Libretos",
            subgenero: "Subgénero",
            ventana: "Ventana",
            temporalidad: "Temporalidad",
            tipo_contrato: "Tipo de contrato",
            empresa_proveedores: "Empresas/Proveedores",
            notifications: "Notificaciones",
            ConfigureNotifications: "Configuración de notificaciones",
            users: "Usuarios",
            parameters: "Parámetros",
            controlMateriales: "Control de materiales",
        },
        adminWork:{
            addWork: "Registro de Obra Literaria",
            updateWork: "Actualización de Obra Literaria",
            addMaterial: "Agregar Material",
            material_type: "Tipo de Material",
            material_description: "Descripción",
            material_amount: "Cantidad",
            material_audio: "Audio",
            material_subtitle: "Subtítulo",
            material_lang: "Idioma",
            material_missing: "Faltantes",
            dropFile: "Arrastra tu archivo o da click aquí",
            noDataMaterials: "No se ha cargado ningún material",
            noData: "No hay datos disponibles",
            additionalInfo: "Material - Información adicional",
            dropFileLimit: "Total de archivos por carga ",
            dropFileLimitWarning: "Límite de archivos por carga superado",
            prospecteUpdated:"Prospecto actualizado correctamente",
            missingProducer:`Debe agregar un productor en el campo vacío`,
            projectUpdated:"Proyecto actualizado correctamente",
            requiredFields: "Completa los campos obligatorios"
        }, 
        playDetail:{
            originalWorkTitle: "Título de la obra primigenia:", 
            otherVersions: "Otras versiones", 
            authors: "Autor(es)", 
            adapters: "Adaptador(es)", 
            productors: "Productor(es)", 
            format: "Formato",
            platform: "Ventana", 
            audience: "Audiencia", 
            schedule: "Horario",
            suggested_schedule: "Horarios sugeridos",
            genre: "Género", 
            subgenre: "Subgénero", 
            theme: "Temática", 
            motivation: "Motivación", 
            temporality: "Temporalidad", 
            ambient: "Ambiente", 
            synopsis: "Sinopsis", 
            characters: "Personajes", 
            evaluation: "Evaluación", 
            material :"Material", 
            moreInfo: "Más Información", 
            validity: "Vigencias", 
            literaryTeams: "Equipos Literarios", 
            shortSynopsis: "Sinopsis Corta", 
            longSynopsis: "Sinopsis Larga", 
            relationshipDiagram: "Diagrama de relaciones",
            name: "NOMBRE", 
            age: "EDAD", 
            characterPsychology:"PSICOLOGÍA DEL PERSONAJE",
            calification: "Calificación",
            rating: "Rating", 
            share: "Share", 
            comentaries: "Comentarios",
            requestScripts: "Solicitar libretos", 
            scripts: "Libretos", 
            spanishScripts: "Libretos Español",
            videos: "Videos",
            images: "Imágenes",
            others: "Otros", 
            bible: "Biblia",
            brochure: "Brochure", 
            escaleta: "Escaleta", 
            more: "Más",
            copyRights: "DERECHOS", 
            country: "País", 
            language: "Idioma", 
            validity: "VIGENCIAS", 
            royalties: "REGALIAS", 
            purchaseYear: "AÑO DE COMPRA", 
            proudctionYear:"Año de producción",
            chapters: "Libretos", 
            airedChapters: "Capítulos al aire",  
            relatdWorks: "Obras Relacionadas", 
            //This comes from DB -- DO NOT CHANGE
            ventas: "Ventas", 
            produccion: "Producción",
            adptation: "Adaptación", 
            original:"Primigenia",
            vigente: "Vigente",
            vencida: "Vencida",
            porVencer: "Por vencer",
            produccionTelevisa: "Producción Cloudgenia",
            produccionExtranjera: "Producción Extranjera",
            noProducida: "No producida",
            inedita: "Inédita",
            unlockWork:"Liberar Obra",
            downloadResume:"Descargar Ficha Técnica"
        }, 
        searchs:{
            sales: "CATÁLOGO COMPLETO", 
            production: "CATÁLOGO DE PRODUCCIÓN", 
            search: "Buscar", 
            filters: "Filtros", 
            filter: "Filtrar", 
            titles: "títulos", 
            results:"Resultados"
        }, 
        markedWorks:{
            favorites: "Favoritos",
            prospectAvailable:"Disponible como prospecto", 
            prospect: "Prospecto", 
            inReviewByOterUser: "Esta obra está en revisión por otro usuario", 
            projectAvailable: "Disponible para Proyecto", 
            project:"En proyecto", 
            projectAssigned: "Esta obra ya está asignada a un usuario"
        }, 
        lang: {
            es: "Español", 
            en: "Inglés", 
            pt: "Portugués", 
            de: "Alemán", 
            ja:"Japonés",
            el:"Griego", 
            tr: "Turco"
        }, 
        //AHDA 29/04/2019
        //Order by value
        actionTypesCatalogue: {
            workUpdateStatus: "Actualización de obra",
            userUpdateStatus: "Actualización de usuario", 
            workUpdateValidity: "Actualización de vigencia de obra",
            extendTerm: "Ampliar plazo",
            asignProject: "Asignar proyecto",
            advance: "Avanzar",
            deleteScripts: "Borrar libretos",
            searchWork: "Búsqueda de obra",
            changeToVideo: "Cambio de material en vídeo",
            shareMaterial: "Compartir material", 
            unlockProject: "Liberar obra",
            edit: "Edición",
            delete: "Eliminación",
            exportCSV: "Exportar reporte",
            finishProject: "Finalizar proyecto",
            login: "Inicio de sesión",
            markAsFavorite: "Marcar como favorito",
            markAsProspect: "Marcar como prospecto",
            register: "Registro de obra",
            requestMaterial: "Solicitar material", 
        }, 
        screens: {
            acervoComposition: "Reporte Composición Acervo Literario", 
            home: "Acervo", 
            login: "Inicio de sesión", 
            notDefined: "-", 
            reporteConsultas: "Reporte Obras más consultadas", 
            search: "Búsqueda general", 
            searchCategory: "Búsqueda por categoría", 
            viewDetail: "Vista detalle", 
            reporteBitacoraMovimientos: "Reporte Bitácora de movimientos", 
            reporteComposicionAcervo: "Reporte Composición de ACERVO literario", 
            reporteProspectos: "Reporte Prospectos", 
            reporteProyectos: "Reporte Proyectos", 
            notificationsPickScript: "Notificaciones - Elegir libretos", 
            configurationUsers: "Configuración de Usuarios", 
            configurationUsers: "Configuración de Usuarios", //AHDA 09/05/2019
            users: "Configuración de Usuarios", //AHDA 09/05/2019
            confLiteraryWorks: "Configuración de Obras Literarias" , //AHDA 09/05/2019
            reporteFavoritos: "Reporte Favoritos", //AHDA 09/05/2019
            reporteObrasLiterarias: "Reporte Obras Literarias", //AHDA 09/05/2019
            notifications: "Notificaciones", //AHDA 04/06/2019
            admin: "Administración de obras"
        }, 
        reports:{
            repAcervoComposition: "Composición Acervo Literario",
            repMostSearchedWorks: "Obras más consultadas",
            reports: "Reportes",
            results: "resultados", 
            download: "Descargar", 
            print: "Imprimir", 
            compProv: "Empresa/Proveedor", 
            title: "Título de la Obra", 
            authors: "Autor(es)/Nickname", 
            country: "País", 
            registerDate: "Fecha de registro", 
            genre: "Género", 
            status: "Estatus", 
            playType: "Tipo Obra", 
            primal: "Primigenia", 
            adaptation: "Adaptación",
            sales: "Ventas", 
            production: "Producción",
            all: "Todos", 
            playEstatus: "Estatus de la Obra", 
            materialType: "Tipo de material",
            contractType: "Tipo de Contrato", 
            startDate: "Fecha Inicio", 
            endDate: "Fecha Final", 
            provider: "Proveedor", 
            date: "Fecha", 
            user: "Usuario", 
            action: "Acción", 
            description: "Descripción",
            repMovementLog: "Bitácora de movimientos", 
            author: 'Autor/Nickname', 
            validity: "Vigencia", 
            purchaseDate: "Fecha de compra", 
            currency: "Moneda",  
            produced: "Producidas", 
            producer: "Productor", 
            format: "Formato", 
            source: "Fuente",
            productionYear: "Año de producción", 
            destiny: "Destino", 
            platform: "Ventana", 
            chapterNumber: "Capítulos", 
            audience: "Audiencia", 
            yes: "Sí", 
            totalSearchs: "Total de consultas", 
            favorites: "Favoritos", 
            assigmentDate: "Fecha de Asignación", 
            dueDate: "Fecha Límite", 
            timeRemaining: "Tiempo Restante (días)", 
            favoriteVotes: "Votos de Favoritos", 
            prospects: "Prospectos", 
            projects: "Proyectos",
            employee:"Número de empleado"
        }, 
        treeTable: {
            rowsPerPage: "Filas por página"
        }, 
        tabMoreInfo:{
            title: "Asignación de obra",
            assignedto:"Proyecto asignado a:",
            assignedUser:"Prospecto asignado a:",
            assignedDate:"Fecha en la que se asignó como prospecto:",
            comments: "Comentarios:",
            assignWork:"Asignar obra",
            unlockWork: "Desbloquear obra",
            finishWork: "Finalizar proyecto",
            commentsPlaceholder:"Escriba sus comentarios"
        }
    }
})

langstr.setLanguage('es'); 

export default langstr; 