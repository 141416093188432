import { Card, CardContent, Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import styles from './nuevaObra-jss'
import PropTypes from 'prop-types'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';


const StepVigencias = ({ classes, updateValues, territories, data,titleTerritories, canViewVigencia }) => {

    const toggle = (panel) => {
        if(canViewVigencia)
            updateValues({ type: panel, date: null, options: {}, comment: "" })
    }

    const handleDate = (e) => {
        const temp = data;
        temp.date = new Date(e);
        updateValues(temp)
    }

    const handleOptions = (e, name) => {
        const temp = data;
        if (name === "year") {
            temp.options[name] = new Date(e).getFullYear().toString()
        } else {
            temp.options[e.target.name] = (e.target.name === "territorio" ? e.target.value : e.target.checked);
        }
        updateValues(temp)
    }

    const handleChange = (e) => {
        const temp = data;
        temp.comment = e.target.value;
        updateValues(temp)
    }

    return <section className={classes.bodyForm}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ExpansionPanel expanded={(data && data.type) === "Vigente"} onChange={() => toggle("Vigente")}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === "Vigente"} disabled={!canViewVigencia}/>
                    <Typography className={classes.stepHeadingExpand}>Vigente</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {(data && data.type) === "Vigente" && <div className={classes.root}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4} >
                                <FormControl fullWidth>
                                    <DatePicker
                                        id="date"
                                        name='date'
                                        keyboard={false}
                                        openTo="year"
                                        views={["year", "month"]}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        value={data.date}
                                        onChange={handleDate}
                                        disabled={!canViewVigencia}
                                        fullWidth
                                        TextFieldComponent={(params) => (
                                            <TextField 
                                                {...params} 
                                                label="Año y Mes" 
                                                placeholder='' 
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body1' component="span" className={classes.checkboxOption}>PUP</Typography>
                                <Checkbox
                                    id='pup'
                                    name='pup'
                                    checked={data.options.pup}
                                    onChange={handleOptions}
                                    disabled={!canViewVigencia}
                                />
                            </Grid>
                            {data.options.pup && 
                            <Grid item xs={12} sm={4}>
                                <Grid item xs={12} sm={4} >
                                    <FormControl fullWidth>
                                        <DatePicker
                                            id="year"
                                            name='year'
                                            keyboard={false}
                                            openTo="year"
                                            views={["year"]}
                                            disableOpenOnEnter
                                            animateYearScrolling={false}
                                            value={data.options.year}
                                            onChange={(e) => handleOptions(e, "year")}
                                            disabled={!canViewVigencia}
                                            fullWidth
                                            TextFieldComponent={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="Año" 
                                                    placeholder='' 
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body1' component="span" className={classes.checkboxOption}>Perpetuidad</Typography>
                                <Checkbox
                                    id='perpetuo'
                                    name='perpetuo'
                                    checked={data.options.perpetuo}
                                    onChange={handleOptions}
                                    disabled={!canViewVigencia}
                                />
                            </Grid>
                        </Grid>
                    </div>}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={(data && data.type) === "Sin Derechos"} onChange={() => toggle("Sin Derechos")}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === "Sin Derechos"} disabled={!canViewVigencia}/>
                    <Typography className={classes.stepHeadingExpand}>Sin Derechos</Typography>
                </ExpansionPanelSummary>
            </ExpansionPanel>

            <ExpansionPanel expanded={(data && data.type) === "Por Vencer"} onChange={() => toggle("Por Vencer")}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === "Por Vencer"} disabled={!canViewVigencia}/>
                    <Typography className={classes.stepHeadingExpand}>Por Vencer</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {(data && data.type) === "Por Vencer" && <div className={classes.root}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4} >
                                <FormControl fullWidth>
                                    <DatePicker
                                        id="date"
                                        name='date'
                                        keyboard={false}
                                        openTo="year"
                                        views={["year", "month"]}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        value={data.date}
                                        onChange={handleDate}
                                        disabled={!canViewVigencia}
                                        fullWidth
                                        TextFieldComponent={(params) => (
                                            <TextField 
                                                {...params} 
                                                label="Año y Mes" 
                                                placeholder='' 
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={(data && data.type) === "Vencida"} onChange={() => toggle("Vencida")}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === "Vencida"} disabled={!canViewVigencia}/>
                    <Typography className={classes.stepHeadingExpand}>Vencida</Typography>
                </ExpansionPanelSummary>
            </ExpansionPanel>

            <ExpansionPanel expanded={(data && data.type) === "Hold Back"} onChange={() => toggle("Hold Back")}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === "Hold Back"} disabled={!canViewVigencia}/>
                    <Typography className={classes.stepHeadingExpand}>Hold back</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {(data && data.type) === "Hold Back" && <div className={classes.root}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <DatePicker
                                        id="date"
                                        name='date'
                                        keyboard={false}
                                        openTo="year"
                                        views={["year", "month"]}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        value={data.date}
                                        onChange={handleDate}
                                        disabled={!canViewVigencia}
                                        fullWidth
                                        TextFieldComponent={(params) => (
                                            <TextField 
                                                {...params} 
                                                label="Año y Mes" 
                                                placeholder='' 
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor='hold-back-territorio'>
                                        {titleTerritories}
                                    </InputLabel>
                                    <Select
                                        id="territorio"
                                        name="territorio"
                                        value={data.options.territorio || ""}
                                        onChange={handleOptions}
                                        className={classes.selectEmpty}
                                        displayEmpty
                                        disabled={!canViewVigencia}
                                    >
                                        {
                                            territories.map((title) => (
                                                <MenuItem key={`titles_${title.value}`} value={title.data}> {title.data} </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </MuiPickersUtilsProvider>

        <Card className={classes.comentariosBox}>
            <CardContent>
                <TextField
                    label="Comentario"
                    fullWidth
                    multiline
                    rows="4"
                    margin="normal"
                    disabled={!canViewVigencia}
                    value={data ? data.comment : ""}
                    onChange={handleChange}
                    className={classes.comentariosInput}
                />
            </CardContent>
        </Card>
    </section>
}

StepVigencias.propTypes = {
    updateValues: PropTypes.func.isRequired
}

export default withStyles(styles)(StepVigencias);