import React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import brand from '../../../../utils/brand';
import PapperBlock from './../../../../components/PapperBlock/PapperBlock';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import { apiPOST } from '../../../../services/service_base';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';
import ls from 'local-storage';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Add from '@material-ui/icons/Add';
import { ButtonAction } from '../../../../atoms';
import { Delete } from '@material-ui/icons';
import { FormHelperText } from '@material-ui/core';

class Parameters extends React.Component {

    state = {
        parameters: [],
        value: 0,
        idSelected: 0,
        isLoading: true,
        openNotification: false,
        messageNotification: "",
        typeNotification: "",
        chkEditParameter: false,
        files: [],
        errors:[]
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this._loadParameters();
    }

    _loadParameters = () => {
        let config = ls.get("user_permissions").filter(x => x.menu_id === 18)[0];
        let chk = JSON.parse(config.grant_configuration);
        apiPOST("/params/get", {
            portal: 1
        }).then(response => {
            this.setState({
                parameters: response.data,
                isLoading: false,
                [`value_${response.data[0].id_parameter}`]: response.data[0].value,
                [`value_${response.data[2].id_parameter}`]: response.data[2] ? JSON.parse(response.data[2].value).total : 1,
                [`size_${response.data[2].id_parameter}`]: response.data[2] ? JSON.parse(response.data[2].value).size : 0,
                idSelected: response.data[0].id_parameter,
                chkEditParameter: chk.chkEditParameter,
                files: response.data[1] ? JSON.parse(response.data[1].value) : []
            });
        }).catch(err => {

        });
    }

    handleChange = (event) => {
        let idParam = event.target.name.split("_")[1];
        this.setState({
            [event.target.name]: event.target.value,
            [`idParam_${idParam}`]: idParam
        });
    }

    handleFile = (event, key) => {
        const temp = this.state.files;
        let cont = 0;
        temp[key][event.target.name] = event.target.value;
        this.state.files.forEach(({ size }) => cont = size === "" ? cont + 0 : cont + parseInt(size))
        this.setState({ files: temp, [`size_${this.state.parameters[2].id_parameter}`]: cont });
    };

    handleAddRemove = (key) => {
        const temp = this.state.files;
        let total = parseInt(this.state[`value_${this.state.parameters[2].id_parameter}`])
        if (key) {
            temp.splice(key, 1);
            //total--;
        } else {
            temp.push({ "type": "", "size": "" });
            total++;
        }
        this.setState({
            files: temp,
            [`value_${this.state.parameters[2].id_parameter}`]: total
        });
    }
    _saveChanges = (e) => {
        e.preventDefault()
        let editParams = new Array();
        let error = false;
        this.state.parameters.map((item, key) => {

            if (key === 0 && this.state[`idParam_${item.id_parameter}`] != undefined) {
                editParams.push({
                    id_parameter: this.state[`idParam_${item.id_parameter}`],
                    new_value: this.state[`value_${item.id_parameter}`]
                });
            }
            if (key === 1) {
                let temp=[];
                this.state.files.forEach((item,key)=>{
                    temp[key]=item.type===''?true:false;
                    if(item.type==='')
                        error=true
                })
                this.setState({errors:temp})
                editParams.push({
                    id_parameter: item.id_parameter,
                    new_value: JSON.stringify(this.state.files)
                });
            }
            if (key === 2) {
                editParams.push({
                    id_parameter: item.id_parameter,
                    new_value: `{"total":${this.state[`value_${item.id_parameter}`]},"size":${this.state[`size_${item.id_parameter}`]}}`
                });
            }
        });
        if (error === false && editParams.length > 0) {
            this.setState({ isLoading: true });
            apiPOST("/params/update", {
                params: JSON.stringify(editParams)
            }).then(response => {
                if (response.status == 200) {
                    this.setState({
                        isLoading: false,
                        messageNotification: "Cambios guardados correctamente",
                        openNotification: true,
                        typeNotification: "success"
                    });
                }
            });
        }
    }

    _reset = () => {
        this._loadParameters();
    }

    handleCloseNotification = () => {
        this.setState({
            openNotification: false
        })
    }

    render() {

        const title = brand.name + ' - Parámetros';
        const description = brand.desc;
        const { classes } = this.props;

        return (
            <div className="marginTopLogo">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>

                {this.state.isLoading ? <LoaderFull /> : ''}

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openNotification}
                    autoHideDuration={6000}
                    onClose={this.handleCloseNotification}>
                    <CustomNotification
                        onClose={this.handleCloseNotification}
                        variant={this.state.typeNotification}
                        message={this.state.messageNotification} />
                </Snackbar>

                <PapperBlock title="Parámetros" desc="">
                    <form onSubmit={this._saveChanges}>
                        <Table className={classNames(classes.table)}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell padding="dense">
                                        <div className={classes.descriptionCell}></div>
                                    </TableCell>
                                    <TableCell className={classes.cellHeading}>
                                        Valor
                                    </TableCell>
                                    <TableCell className={classes.cellHeading}>
                                        Tamaño max permitido en GB
                                    </TableCell>
                                    <TableCell className={[classes.cellHeading, classes.actionCellPadding]}>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.parameters.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell padding="dense" className={classes.alignTop}>
                                                    {item.description}
                                                </TableCell>
                                                <TableCell className={classes.alignTop}>
                                                    <div className={classes.actionCell}>
                                                        {["NUMBER", "OBJECT"].includes(item.param_type) && <div>
                                                            <Input
                                                                id={`value_${item.id_parameter}`}
                                                                name={`value_${item.id_parameter}`}
                                                                value={this.state[`value_${item.id_parameter}`]}
                                                                className={[classes.inputMini, classes.inputSelect]}
                                                                fullWidth
                                                                type="number"
                                                                disabled={!this.state.chkEditParameter}
                                                                onChange={(event) => this.handleChange(event)}
                                                                required
                                                                inputProps={
                                                                    { min: 0 }
                                                                }
                                                            />
                                                        </div>}

                                                        {item.param_type === "ARRAY" &&
                                                            this.state.files.map((item, key) =>
                                                                <div key={key} className={classes.arrayRowItem}>
                                                                    <FormControl
                                                                        fullWidth
                                                                        className={classes.tableFormControl}
                                                                    >
                                                                        <Select
                                                                            id="type"
                                                                            name="type"
                                                                            value={item.type}
                                                                            disabled={!this.state.chkEditParameter}
                                                                            onChange={(event) => this.handleFile(event, key)}
                                                                            className={classNames(classes.inputMini, classes.inputSelect)}
                                                                            fullWidth
                                                                            error={this.state.errors[key]}
                                                                        >
                                                                            {["PDF", "Power Point", "Word", "Video", "Imagen", "Comprimido"].map((type) => (<MenuItem value={type} key={type}> {type}</MenuItem>))}
                                                                        </Select>
                                                                        {this.state.errors[key] ? <FormHelperText>Campo requerido</FormHelperText> : null}
                                                                    </FormControl>
                                                                </div>
                                                            )}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.alignTop}>
                                                    <div className={classes.actionCell}>
                                                        {item.param_type === "ARRAY" &&
                                                            this.state.files.map((item, key) =>
                                                                <div key={key} className={classes.arrayRowItem}>
                                                                    <Input
                                                                        name="size"
                                                                        value={item.size}
                                                                        className={[classes.inputMini, classes.inputSelect]}
                                                                        fullWidth
                                                                        type="number"
                                                                        disabled={!this.state.chkEditParameter}
                                                                        onChange={(event) => this.handleFile(event, key)}
                                                                        required
                                                                        inputProps={
                                                                            { min: 0 }
                                                                        }
                                                                    />
                                                                </div>)
                                                        }
                                                        {item.param_type === "OBJECT" &&
                                                            <>
                                                                <Input
                                                                    id={`size_${item.id_parameter}`}
                                                                    name={`size_${item.id_parameter}`}
                                                                    value={this.state[`size_${item.id_parameter}`]}
                                                                    className={[classes.inputMini, classes.inputSelect]}
                                                                    fullWidth
                                                                    type="number"
                                                                    disabled={!this.state.chkEditParameter}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                    required
                                                                    inputProps={
                                                                        { min: 0 }
                                                                    }
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell className={[classes.alignTop, classes.actionCellPadding]}>
                                                    <div className={classes.actionCell}>
                                                        {item.param_type === "ARRAY" &&
                                                            this.state.files.map((_, key) =>
                                                                <div key={key} className={classes.arrayRowItem}>
                                                                    <div>
                                                                        {(this.state.files.length > 1 && key > 0) &&
                                                                            <ButtonAction
                                                                                rounded
                                                                                color="default"
                                                                                disabled={!this.state.chkEditParameter}
                                                                                className={classNames(classes.buttonAction, classes.buttonActionDelete)}
                                                                                onClick={() => this.handleAddRemove(key)}>
                                                                                <Delete />
                                                                            </ButtonAction>
                                                                        }
                                                                        {key + 1 === this.state.files.length &&
                                                                            <ButtonAction
                                                                                rounded
                                                                                color="default"
                                                                                disabled={!this.state.chkEditParameter}
                                                                                className={classes.buttonAdd}
                                                                                onClick={() => this.handleAddRemove()}>
                                                                                <Add />
                                                                            </ButtonAction>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Grid container justify='flex-end'>
                                    <Button disabled={!this.state.chkEditParameter} type={'submit'} variant="contained" size="small" color="primary" className={classes.button}>
                                        Guardar
                                    </Button>
                                    <Button disabled={!this.state.chkEditParameter} onClick={() => this._reset()} variant="contained" size="small" color="primary" className={classes.button}>
                                        Cancelar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </PapperBlock>
            </div>
        )
    }
}

export default withStyles(styles)(Parameters);