const drawerWidth = 325;

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    rootDrawerOpen: {
        marginRight: drawerWidth,
    },
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    searchWrapper: {
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        display: 'flex',
        // flexFlow: 'row nowrap',
        // [theme.breakpoints.down('sm')]: {
        //     marginTop: theme.spacing.unit * 0.2,
        // },
    },
    searchBox: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        paddingInline: theme.spacing.unit + 'px',
        paddingBlock: (theme.spacing.unit * 1.25) +'px',

        '& [class*="suggestionsContainer"]': {
            '& ul': {
                border: 0,
                boxShadow: '0px 5px 14px -5px #0000002e',
                borderRadius: 4,
            }
        }
    },
    searchIcon: {
        width: 'auto',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    searchInput: {
        flex: 1,
        whiteSpace: 'normal',

        '&:focus': {
            outline: 0,
        },

        '& input': {
            backgroundColor: 'transparent',
            border: 0,
            paddingLeft: theme.spacing.unit,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
        },

        '& [class*="InputGroup"]': {
            border: 0,
            boxShadow: 'none',

            '& [class*="IconGroup-IconGroup-IconGroup"]':{
                display: 'none',
                position: 'relative',
            },
        },

        '& [class*="TagsContainer"]': {
            margin: '0 !important',
        },

        '& [class*="InputWrapper"]': {
            margin: '0 !important',
        },

        '& .search-icon': {
            display: 'none',
        }
    //     margin: 0, // Reset for Safari
    },
    searchButtons: {
        alignItems: 'center',
        display: 'flex',
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    searchBtn: {
        marginLeft: theme.spacing.unit,
        '& svg': {
            marginLeft: 10,
        },
    },
    searchDrawer: {
        '& div[role=document]': {
            padding: theme.spacing.unit * 2,
            maxWidth: drawerWidth,
            width: '95vw',
        },
    },
    textRight: {
        float: 'right',
        minHeight: '48px',
    },
    title: {
        margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px 0 0`,
        paddingBottom: theme.spacing.unit * 2,
        position: 'relative',
        textTransform: 'capitalize',
        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 40,
            borderBottom: `4px solid ${theme.palette.primary.main}`,
        },
    },
    titleCategory: {
        fontSize: '1.2rem',
        color: theme.palette.primary.main,
    },
    labelWhite: {
        color: '#fff',
        '& span svg': {
            fill: 'white',
            stroke: 'unset',
            strokeWidth: 0,
        },
        '& ~ span': {
            color: '#fff !important',
            paddingTop: theme.spacing.unit / 4
        },
    },

    filtrarBtn: {
        margin: '0 15px 0 70px',
        '& svg': {
            marginLeft: 10,
        },
    },
    filterContainer: {
        width: '317px',
        '@media (max-width: 350px)': {
            maxWidth: 275,
        },
    },
    filterForm: {
        padding: theme.spacing.unit * 3,
        '& ul': {
            maxHeight: 2000000,
        },
    },
    fixedTitle: {
        width: 317,
        padding: '0 24px 15px 0',
        backgroundColor: '#ffffff',
        position: 'sticky',
        top: 0,
        right: 0,
        zIndex: 3000,
        '@media (max-width: 350px)': {
            maxWidth: 275,
        },
        '& h5': {
            display: 'inline-block !important',
        },
    },
    contentWidth: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentWidthShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //marginRight: drawerWidth,
        width: 'auto',
    },
    drawer: {
        position: 'relative',
        zIndex: 9999,
        '@media (max-width: 350px)': {
            '& > div': {
                maxWidth: 275,
                overflowX: 'hidden',
            },
        },
    },
    m5: {
        marginTop: '1rem 0px !important',
    },
    itemTotal: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '2rem 0 1rem 0',
        fontStyle: 'italic',
        '& h2': {
            fontSize: '1rem',
            fontWeight: '400',
        },
    },
    totalBox: {
        marginRight: '1rem',
        '& :last-child': {
            marginRight: '0',
        },
    },
    displayNone: {
        display: 'none',
    },
    titleDrawer: {
        alignItems: 'center',
        color: 'black',
        display: 'flex',
        marginBottom: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit,
        position: 'relative',
        textTransform: 'capitalize',

        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 40,
            borderBottom: `4px solid ${theme.palette.primary.main}`,
        },
    },
    drawerList: {
        padding: 0,
    },
    drawerItem: {
        padding: theme.spacing.unit / 2,
    },
    drawerItemStrong: {
        '& span': {
            color: '#777',
            fontWeight: 600,
            // textTransform: 'uppercase',
        }
    },
    drawerCheckbox: {
        padding: `${theme.spacing.unit / 2}px !important`,
    },
    drawerBadge: {
        position: 'absolute',
        right: theme.spacing.unit * 1.2
    },
    drawerItemText: {
        flex: 1,
    }
});

export default styles;
