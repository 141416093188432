import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Slider from "react-slick";
import { Player, BigPlayButton } from 'video-react';
import { S3Image } from 'aws-amplify-react';
import { getFile } from '../../services/storage';


//styles 
import styles from './../CardPaper/cardStyle-jss'; 
import './../CardPaper/style.css'
import 'video-react/dist/video-react.css'


class MediaModalSlider extends React.Component{
    
  state = {
    media: []
  }

  handleBeforeChange = (oldIndex, newIndex) => {
    // JCMY 11/07/2019 incidencia 828
    const auxMedia = [...this.props.media]; 
    if(auxMedia[oldIndex].type === "video"){
      this.refs[`video${oldIndex}`].pause();
    }
  }

  handleGetFile = (url, index) => {
    const auxMedia = [...this.state.media]; 

    getFile(url, 'public').then(response => {
      auxMedia[index].url = response 
      this.setState({media: auxMedia}); 
    }).catch(err => {
      this.setState({media: auxMedia});  
    })
  }

  componentDidMount(){
    
    let auxMedia = [...this.props.media]; 
    let auxKey = [...this.props.media]; 
    for(let i = 0; i < auxMedia.length; i++){
      
      if(auxMedia[i].type === "video"){
        console.log("multimedia", auxMedia[i]);
        // isanchez 08.05.2019 add if for video in preview
        if(auxMedia[i].key && auxMedia[i].preview === ""){
          getFile(auxMedia[i].key, 'public').then(response => {
            auxMedia[i].url = response; 
          }).catch(err => {
            auxMedia[i].url = "";
          }).finally(() => {
            if(i === auxMedia.length - 1){
              this.setState({media: auxMedia}); 
            }
          })
        }else if(auxMedia[i].url !== "" && auxMedia[i].preview === undefined){

          let key = auxKey[i].url; 
          console.log("validate url", this.validURL(key));
          
          if(this.validURL(key)){
            auxMedia[i].url = key; 
            if(i === auxMedia.length - 1){
              this.setState({media: auxMedia}); 
            }
          }else{
            getFile(key, 'public').then(response => {
              console.log("url", response)
              auxMedia[i].url = response; 
            }).catch(err => {
              auxMedia[i].url = "";
            }).finally(() => {
              // if(i === auxMedia.length - 1){
                this.setState({media: auxMedia}, () => {
                  console.log("meduia", this.state.media)
                }); 
              // }
            })
          }

        }else{
          auxMedia[i].url = auxMedia[i].preview; 
          if(i === auxMedia.length - 1){
            this.setState({media: auxMedia}); 
          }
        }
      }else{
        if(i === auxMedia.length - 1){
          this.setState({media: auxMedia}); 
        }
      }
      
    } 
  }
  
  validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  render(){

    const { handleCloseModal, classes, media } = this.props; 

    //Slider settings
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true, 
        mobileFirst: true
      };

    return(
      <div className={classNames(classes.cardModal, 'modal-slider')}>
        {/* GEO 05/06/19 Se agrega clase para que el boton cerrar siempre esté delante de las imágenes */}
        <Button  className={classNames(classes.button, classes.rightAction, classes.zIndexBtn )} variant="fab" mini aria-label="Delete" color="primary" onClick={handleCloseModal}>
          <Close />
        </Button >
        <Slider {...settings} beforeChange={this.handleBeforeChange}>
          {
            this.state.media.map((material, index) => (
              <div className={classes.modalSlider} key={`material${index}`}>
              {/* GEO 11/04/2019
              Se agregan estilos para que las imágenes tengan un alto máximo definido y se vean completas en el slider. */}
                {
                  material.type === 'video' ? 
                      <Player
                        playsInline={false}
                        preload="metadata"
                        src={`${material.url}#t=0.5`}
                        ref={`video${index}`}>
                        <BigPlayButton position="center" />
                      </Player> : 
                      <S3Image theme={{ photoImg: { width: '964px', maxHeight: '600px', objectFit: 'contain' } }} imgKey={material.url} />
                }
              </div>
            ))
          }
        </Slider>
      </div>
    )
  }
}

MediaModalSlider.propTypes = {
  classes: PropTypes.object,
  handleCloseModal : PropTypes.func.isRequired, 
  media: PropTypes.array.isRequired
}

MediaModalSlider.defaultProps = {
  openModal : false, 
  media: []
}

export default withStyles(styles)(MediaModalSlider); 