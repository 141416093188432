import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ProductCard from './../CardPaper/ProductCard';
import ProductDetail from './ProductDetail';
import Slider from 'react-slick'; 

import {apiPOST, returnCurrentUserId} from '../../services/service_base';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
});

class ProductGallery extends React.Component {
 
  constructor(props){
    super(props); 
    
    this.state = {
      listView: false,
      open: false,
      selected: 0,
      selectedProduct:[],
      detailWork: 0, 
      loading: false
    }
  }
  
  //OPEN DETAIL
  //isanchez 09
  handleDetailOpen = (product) => {
    console.log("product", product);
    // JCMY 16/01/19
    this.setState(
      {
        detailWork : 0
      }, () => {
        const request = {
          user: returnCurrentUserId(),
          idObra: product.id_obras, 
          screen: this.props.screen 
        }
    
        //MARK AS SEARCH IN AUDIT
        apiPOST('/obras/markAsSearch',request).then(response =>{
            if(response.status === 200){
              if(this.props.addToList){
                console.log("Edit list"); 
                this.props.addToList(product, 102, true)
              }
            }
        })
        
        if(this.state.open){
          this.setState({ selected : product.id_obras, selectedProduct: product, loading: true});
        }else{
          this.setState({ open: true, selected : product.id_obras, selectedProduct: product, loading: true});
        }
        
        //AHDA 24/04/2019
        //Added clasification parameter 
        let clasification = "none"; 
        const {chkProductionGeneralView, chkSalesGeneralView} = this.props; 
        if(chkProductionGeneralView && chkSalesGeneralView){
          clasification = "none"; 
        }
        else if(!chkProductionGeneralView && chkSalesGeneralView){
          clasification = "ventas";
        }
        else if(chkProductionGeneralView && !chkSalesGeneralView){
          clasification = "produccion";
        }
    
        // Obteniendo detalle de la obra
        const requestGetObra = {
          idObra: product.id_obras, 
          clasification: clasification
        }
        
        apiPOST('/obras/getDetail',requestGetObra).then(response =>{
          if(response.status === 200){
            //isanchez view detail work from valid => 1
            this.setState({detailWork : response.data, loading: false})
          }else{
            this.setState({openError:true, loading:false, open: false});
          }
        }).catch(error=>{
           this.setState({openError:true, loading:false, open: false});
        });
      }
    )
  };

  //CLOSE DETAIL
  handleClose = () => {
    this.setState({ open: false , selected : 0, selectedProduct:[]});
  };
  
  render() {
    const { listView, open } = this.state;
    
    const { 
      dataProduct,
      canFavorite,
      canAddProspect,
      canApplyForMaterial,
      canAssignProyect,
      canEndWork,
      canShareMaterial,
      canUnlockWork,
      canViewWorkBlock, 
      chkViewScripts, 
      chkViewSinopsis,
      chkViewPersonaje, 
      chkViewEval,
      chkViewMaterial,
      chkViewMoreInfo,
      chkViewVigencia,
      chkViewEquiposLit,
      chkDownloadFT,
      chkProductionGeneralView, //AHDA 03/06/2019
      chkSalesGeneralView, //AHDA 03/06/2019
      productor
    } = this.props;
    
    const settings = {
      dots: false,
      infinite: false,
      centerMode: false,
      speed: 500,
      autoplaySpeed: 500,
      pauseOnHover: true,
      autoplay: false,
      slidesToShow: 7,
      slidesToScroll: 7,
      cssEase: 'ease-out',
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            arrows: true,
            slidesToShow: 7,
            slidesToScroll: 7
          }
        },
        {
          breakpoint: 1680,
          settings: {
            arrows: true,
            slidesToShow: 6,
            slidesToScroll: 6
          }
        },
        {
          breakpoint: 1440,
          settings: {
            arrows: true,
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1280,
          settings: {
            arrows: true,
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 959,
          settings: {
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 840,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 620,
          settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 400,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div>
        {this.state.detailWork !== 0 && 
          <ProductDetail
            open={open}
            close={this.handleClose}
            detailContent={this.state.selectedProduct}
            productIndex={this.state.selected}
            detailWork={this.state.detailWork}  
            handleDetailOpen={this.handleDetailOpen}
            loading={this.state.loading}
            addToList={this.props.addToList}
            canFavorite={canFavorite}
            canAddProspect={canAddProspect}
            canApplyForMaterial={canApplyForMaterial}
            canAssignProyect={canAssignProyect}
            canEndWork={canEndWork}
            canShareMaterial={canShareMaterial}
            canUnlockWork={canUnlockWork}
            canViewWorkBlock={canViewWorkBlock}
            chkViewScripts={chkViewScripts}
            chkViewSinopsis={chkViewSinopsis}
            chkViewPersonaje={chkViewPersonaje}
            chkViewEval={chkViewEval}
            chkViewMaterial={chkViewMaterial}
            chkViewMoreInfo={chkViewMoreInfo}
            chkViewVigencia={chkViewVigencia}
            chkViewEquiposLit={chkViewEquiposLit}
            chkDownloadFT={chkDownloadFT}

            chkProductionGeneralView={chkProductionGeneralView}
            chkSalesGeneralView={chkSalesGeneralView}
            productor={productor}
            
            screen={this.props.screen}
          />
        }
        <Slider {...settings}>
          {
            dataProduct.map((product, index) => {
              if (product === undefined) {
                return null;
              }
              return (
                <Grid container alignItems="flex-start" justify="flex-start" direction="row" spacing={16} key={index.toString()}>
                  <Grid item xs={12}>
                    {/*AHDA 03/05/2019: Added canViewSales, canViewProduction*/}
                    {/*AHDA 19/06/2019: Added favorites, prospects, projects*/}
                    <ProductCard
                      list={listView}
                      detailOpen={() => 
                        this.handleDetailOpen(product)                        
                      }   
                      product={product}  
                      handleNotifications={() => this.props.handleNotifications()}
                      screen={this.props.screen}
                      canFavorite={canFavorite}
                      canAddProspect={product.obra_vigencia==="Vigente"&&canAddProspect}
                      canApplyForMaterial={canApplyForMaterial}
                      canAssignProyect={canAssignProyect}
                      canEndWork={canEndWork}
                      canShareMaterial={canShareMaterial}
                      canUnlockWork={canUnlockWork}
                      canViewWorkBlock={canViewWorkBlock}
                      addToList={this.props.addToList}
                      size={"269x200"}

                      canViewProduction={chkProductionGeneralView}
                      canViewSales={chkSalesGeneralView}
                      productor={productor}

                      favorites={this.props.favorites}
                      prospects={this.props.prospects}
                      projects={this.props.projects}
                    />
                  </Grid> 
                </Grid>
              );

            })
          }
        </Slider> 
      </div>
    );
  }
}


ProductGallery.propTypes = {
  classes: PropTypes.object.isRequired,
  dataProduct: PropTypes.array,
  handleNotifications: PropTypes.func,
  canFavorite:PropTypes.bool,
  canAddProspect:PropTypes.bool,
  canApplyForMaterial:PropTypes.bool,
  canAssignProyect:PropTypes.bool,
  canEndWork:PropTypes.bool,
  canShareMaterial:PropTypes.bool,
  canUnlockWork:PropTypes.bool,
  canViewWorkBlock:PropTypes.bool, 
  chkProductionGeneralView: PropTypes.bool,
  chkSalesGeneralView: PropTypes.bool, 
  chkViewScripts: PropTypes.bool,
  chkViewSinopsis: PropTypes.bool, 
  chkViewPersonaje: PropTypes.bool, 
  chkViewEval:PropTypes.bool, 
  chkViewMaterial:PropTypes.bool, 
  chkViewMoreInfo:PropTypes.bool, 
  chkViewVigencia:PropTypes.bool, 
  chkViewEquiposLit: PropTypes.bool,
  chkDownloadFT: PropTypes.bool,
  //showDetail: PropTypes.func.isRequired, 
  favorites: PropTypes.number, //AHDA 19/06/2019
  prospects: PropTypes.number, //AHDA 19/06/2019
  projects: PropTypes.number, //AHDA 19/06/2019
};

//AHDA 30/04/2019
//Default props 
ProductGallery.propTypes = {
  canFavorite: false,
  canAddProspect: false,
  canApplyForMaterial: false,
  canAssignProyect: false,
  canEndWork: false,
  canShareMaterial: false,
  canUnlockWork: false,
  canViewWorkBlock: false, 
  chkProductionGeneralView:  false,
  chkSalesGeneralView:  false,
  chkViewScripts: false, 
  chkViewSinopsis: false, 
  chkViewPersonaje: false, 
  chkViewEval:false, 
  chkViewMaterial:false, 
  chkViewMoreInfo:false, 
  chkViewVigencia:false, 
  chkViewEquiposLit: false,
  chkDownloadFT: false,
  favorites: 0, //AHDA 19/06/2019
  prospects: 0, //AHDA 19/06/2019
  projects: 0, //AHDA 19/06/2019
}

export default withStyles(styles)(ProductGallery);