import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import brand from '../../../../utils/brand';
import ContactHeader from '../../../../components/Contact/ContactHeader';
import ContactList from '../../../../components/Contact/ContactList';
import ContactDetail from '../../../../components/Contact/ContactDetail';
import AddContact from '../../../../components/Contact/AddContact';
import LoaderFull from '../../../../components/Loader/LoaderFull'; 

import styles from '../../../../components/Contact/contact-jss';

//Services 
import { apiPOST, returnCurrentUserId, updateCurrentUser } from '../../../../services/service_base'; 
import { saveUserPhoto } from '../../../../services/storage'; 
import ls from 'local-storage';
//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

class Contact extends React.Component {

    state = {
        open: false,
        close: false,
        selectedUser: 1,
        selectedData: undefined,
        originalData: [], 
        searchData: [], 
        roles: [],
        //Errors 
        errType: "error", 
        errMessage: "", 
        openError: false, 
        //Loading 
        isLoading: false, 
        isFullLoading: false,
        chkBlockUser: false,
        chkEditUser: false,
        chkUnlockUser: false,
        chkViewUser: false
    }

    //Notifications 
    handleCloseError = () => {
        this.setState({openError: false}); 
    }

    //Life cycle 
    componentDidMount() {
        this.setState({ isLoading: true });
        let config = ls.get("user_permissions").filter(x => x.menu_id === 7)[0];
        console.log("User Permissions", JSON.parse(config.grant_configuration));
        let chk = JSON.parse(config.grant_configuration);

        this.setState({
            chkBlockUser: chk.chkBlockUser,
            chkEditUser: chk.chkEditUser,
            chkUnlockUser: chk.chkUnlockUser,
            chkViewUser: chk.chkViewUser
        });
        
        //AHDA 16/05/2019: Added valdation for refreshing
        apiPOST('/catalogue/get', {idCatalogo: 33}, true).then(response => {
            if(response.status === 200){
                this.setState({roles: response.data}); 
            }
        }); 

       if(chk.chkViewUser){
            //Get initial data 
        apiPOST('/user/getAll', {"portal" : 1}).then(
            response => {
                console.log(response); 
                if(response.status === 200){
                    //Todo: map data
                    this.setState({searchData: response.data, originalData: response.data, isLoading: false});
                }else{
                    this.setState({
                        isLoading: false, 
                        openError: true, 
                        errType: "error", 
                        errMessage:"Algo sali� mal, intenta de nuevo m�s tarde"});           
                }
            }
        ).catch(
            err => {
                console.log(err); 
                this.setState({
                    isLoading: false, 
                    openError: true, 
                    errType: "error", 
                    errMessage:"Algo sali� mal, intenta de nuevo m�s tarde"}); 
            }
        )
       }
    }

    ////////////////////////// Edit Form
    
    handleDropErrors = (message) => {
        this.setState({
            isLoading: false, 
            openError: true, 
            errType: "warning", 
            errMessage:message}); 
    }
    
    submitContact = (item, avatar) => {

        //TODO: validate if new image
        let errFile = false; 
        
        this.setState({ isFullLoading: true });
        if(avatar.length > 0){
            saveUserPhoto(avatar[0], item).then(response => {
                item.avatar = response.key; 
                //AHDA 05/06/2019: Added thumb empty
                item.thumb = [
                    { "40x40": null }, {"100x100" : null}
                ]; 
            }).catch(err => {
                errFile = true; 
            }).finally(
                () => {
                    this.editUser(item, errFile);
                }
            )
        }else{
            this.editUser(item);
        }
    }

    _closeAddUser = () => {
        this.setState({ open: false });
    }

    edit = (data) => {
        this.setState({ open: true, selectedData: data });
    }

    editUser = (item, errFile) => {
        let body = {
            user_id : item.id_usuario, 
            new_name : item.nombre,
            new_last_name : item.apellidos,
            new_id_empleado : item.numEmpleado,
            new_rol : item.id_rol,
            new_status : item.estatus,
            new_position : item.puesto,
            new_bussiness_unit : item.unidad_negocio,
            new_portrait: item.avatar,
            user_who_updates: returnCurrentUserId(),
            portal: 'acervo',
            screen: 'configurationUsers'
        }

        apiPOST('/user/update', body).then(
            response => {
                if(response.status === 200){

                    //If user is editing its profile, refresh
                    if(item.id_usuario === returnCurrentUserId()){
                        //Change refresh to a listener later 
                        if(updateCurrentUser(item)){
                            window.location.reload(); 
                        }
                    }

                    let auxSearchData = [...this.state.searchData], auxOriginalData = [...this.state.originalData]; 
                    let auxIndex = auxSearchData.findIndex(u => u.id_usuario == item.id_usuario), 
                        auxOriginalIndex = auxOriginalData.findIndex(u => u.id_usuario == item.id_usuario); 
                    
                    if(auxIndex > -1){
                        auxSearchData[auxIndex] = item; 
                    }

                    if(auxOriginalIndex > -1){
                        auxOriginalData[auxOriginalIndex] = item; 
                    }

                    this.setState({
                        open: false,
                        isFullLoading : false, 
                        searchData: auxSearchData, 
                        originalData: auxOriginalData, 
                        selectedData: item, 
                        openError: true, 
                        errType: errFile ? "error" : "success", 
                        errMessage: errFile ? "No se pudieron actualizar todos los datos" : "Se actualizaron los datos del usuario"
                    });
                }else{
                    this.setState({
                        open: false,
                        isFullLoading: false,
                        openError: true, 
                        errType: errFile ? "error" : "success", 
                        errMessage: errFile ? "No se pudieron actualizar todos los datos" : "Se actualizaron los datos del usuario"
                    }); 
                }
            }
        ).catch(
            err => {
                console.log(err); 
                this.setState({
                    open: false, 
                    isFullLoading: false,
                    openError: true, 
                    errType: errFile ? "error" : "success", 
                    errMessage: errFile ? "No se pudieron actualizar todos los datos" : "Se actualizaron los datos del usuario"
                }); 
            }
        )
    }
    ////////////////////////// End Edit Form

    // Detail
    showDetail = (data) => {
        this.setState({ selectedData: data })
    }

    // Search
    search = (event) => {
        let key = event.target.value.toUpperCase(); 

        //Get all
        if(key === ""){
            this.setState({searchData: [...this.state.originalData]}); 
            return false; 
        }
        
        //AHDA 22/04/2019
        //Changed to originalData
        let auxData = [...this.state.originalData]; 

        //AHDA 22/04/2019
        //Validation for empty data
        auxData = auxData.filter(user => {
            //AHDA 04/06/2019: Added last name to search
            if(user.nombre && user.portal_profile && user.apellidos){
                // JCMY 05/07/2019 incidencia 719
                const completeName = (user.nombre+" "+user.apellidos).replace(/([\ \t]+(?=[\ \t])|^\s+|\s+$)/g, '');
                if(completeName.toUpperCase().indexOf(key) > -1 || user.portal_profile.toUpperCase().indexOf(key) > -1)
                    return user; 
            }
        }); 

        this.setState({searchData: auxData}); 
    }

    clearSearch = () => {
        this.setState({searchData: [...this.state.originalData]});
    }

    //Block User
    handleBlockUser = (data) => {
        console.log(data); 
        
        let body = {
            user_id: data.id_usuario,
            user_status: data.estatus, 
            user_who_updates: returnCurrentUserId(), 
            portal: "acervo", 
            screen: "configurationUsers"
        }
        
        this.setState({isFullLoading: true}); 

        apiPOST('/user/block', body).then(
            response => {
                if(response.status === 200){
                    //TODO: replace user in arrays with new status and selected user 
                    let auxSearchData = [...this.state.searchData], auxOriginalData = [...this.state.originalData]; 
                    let auxIndex = auxSearchData.findIndex(u => u.id_usuario == data.id_usuario), 
                        auxOriginalIndex = auxOriginalData.findIndex(u => u.id_usuario == data.id_usuario); 
                    
                    if(auxIndex > -1){
                        auxSearchData[auxIndex].estatus = body.user_status;
                    }

                    if(auxOriginalIndex > -1){
                        auxOriginalData[auxOriginalIndex].estatus = body.user_status;
                    }

                    this.setState({
                        isFullLoading : false, 
                        searchData: auxSearchData, 
                        originalData: auxOriginalData, 
                        selectedData: data, 
                        openError: true, 
                        errType: "success", 
                        errMessage:"Se actualizaron los datos del usuario"
                    });
                }else{
                    this.setState({
                        isFullLoading: false, 
                        openError: true, 
                        errType: "error", 
                        errMessage:"Algo salio mal, intenta de nuevo mas tarde"});
                }
            }
        ).catch(
            err => {
                console.log(err); 
                this.setState({
                    isFullLoading: false, 
                    openError: true, 
                    errType: "error", 
                    errMessage:"Algo salio mal, intenta de nuevo mas tarde"});           
            }
        )
    }

    render() {
        const title = brand.name + ' - Usuarios';
        const description = brand.desc;
        const {
            classes,
            hideDetail,
            showMobileDetail
        } = this.props;

        return (
            <div className="marginTopLogo">
                {
                    this.state.isFullLoading ? <LoaderFull /> : null
                }
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="twitter:title" content={title} />
                    <meta property="twitter:description" content={description} />
                </Helmet>
                {/* <Notification close={() => closeNotif()} message={messageNotif} /> */}
                <div className={classes.root}>
                    {/*AHDA 30/05/2019: Added title*/}
                    <ContactHeader
                        hideDetail={hideDetail}
                        total={this.state.searchData.length}
                        showMobileDetail={showMobileDetail}
                        title={"Usuarios"}
                    />
                    
                    {/*Contact list*/}
                    <ContactList
                    clippedRight
                    itemSelected={this.state.selectedUser}
                    dataContact={this.state.searchData}
                    showDetail={this.showDetail}
                    search={this.search}
                    isLoading={this.state.isLoading}
                    clearFilter={this.clearSearch}
                    />
                    
                    {/*Contact Detail*/}
                    <ContactDetail
                    showMobileDetail={showMobileDetail}
                    dataContact={this.state.searchData}
                    edit={this.edit}
                    item={this.state.selectedData}
                    blockUser={this.handleBlockUser}
                    canEdit={this.state.chkEditUser}
                    canBlock={this.state.chkBlockUser}
                    canUnblock={this.state.chkUnlockUser}
                    />
                </div>
                
                {
                    this.state.selectedData ? 
                    <AddContact
                        openForm={this.state.open}
                        closeForm={this._closeAddUser}
                        submit={this.submitContact}
                        dataInit={this.state.selectedData}
                        rolesCatalogue={this.state.roles}
                        dropValidations={this.handleDropErrors}
                    /> : null
                }

                {/*For notifications*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openError}
                    autoHideDuration={9000}
                    onClose={this.handleCloseError}>
                    <CustomNotification
                        onClose={this.handleCloseError}
                        variant={this.state.errType}
                        message={this.state.errMessage} />
                </Snackbar>
            </div>
        );
    }
}

Contact.propTypes = {
    classes: PropTypes.object,
    avatarInit: PropTypes.string,
    fetchData: PropTypes.func,
    showDetail: PropTypes.func,
    hideDetail: PropTypes.func,
    keyword: PropTypes.string,
    open: PropTypes.bool,
    showMobileDetail: PropTypes.bool,
    add: PropTypes.func,
    close: PropTypes.func,
    submit: PropTypes.func,
    edit: PropTypes.func,
    remove: PropTypes.func,
    favorite: PropTypes.func,
    search: PropTypes.func,
    dataContact: PropTypes.object,
    itemSelected: PropTypes.number,
    closeNotif: PropTypes.func,
    messageNotif: PropTypes.string,
};

export default withStyles(styles)(Contact);
