import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../Pages/Catalogos/styles-jss'

import Toolbar from '@material-ui/core/Toolbar'; 
//import Tooltip from '@material-ui/core/Tooltip';
//import Button from '@material-ui/core/Button';
//import classNames from 'classnames';
import PropTypes from 'prop-types';
//import AddIcon from '@material-ui/icons/Add';

class Header extends React.Component {
    state = {};
    
    render(){
        
        const { title, classes } = this.props;
        
        return (
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                    {title}
                    </div>
                    <div className={classes.spacer} />
                    {/* <div className={classes.actions}>
                        <Tooltip title={tooltip}>
                        <Button variant="contained" onClick={openForm} color="secondary" className={classes.button}>
                            <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> {textBtn}
                        </Button>
                        </Tooltip>
                    </div> */}
                </Toolbar>
        )
    }
}


Header.propTypes = { 
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired, 
    openForm: PropTypes.func};
  
  export default withStyles(styles)(Header);