import classNames from 'classnames';

/**
 * Returns the corresponding color for a `Clasificacion` value.
 * 
 *  ventas,
 *  produccion,
 *  adptation -> adaptacion,
 *  original -> primigenia,
 *  vigente,
 *  vencida,
 *  porVencer,
 *  produccionTelevisa,
 *  produccionExtranjera,
 *  noProducida,
 *  inedita
 * 
 * @param {ClassNameMap<string>>} classes 
 * @param {string} clasificacion 
 * @returns {string} Corresponding color for the `clasificacion`.
 */
export function getChipClassNames(classes, clasificacion) {

    let color = null;
    let base = classes.chipTipo;

    switch (clasificacion) {
        case 'ventas':
            color = classes.chipPrimary;
            break;
        case 'Vigente':
            color = classes.chipVigente;
            break;
        case 'Por Vencer':
            color = classes.chipPorVencer;
            break;
        case 'Vencida':
        case 'Sin Derechos':
        case 'Hold Back':
            color = classes.chipVencida;
            break;
        case 'primigenia':
            color = classes.chipPrimigenia
            break;
        case 'adaptacion':
            color = classes.chipAdaptacion
            break;
        case 'Producción Cloudgenia':
            base = classes.chipTipoBorder;
            color = classes.chipProduccionTelevisa
            break;
        case 'Producción Extranjera':
            base = classes.chipTipoBorder;
            color = classes.chipProduccionExtranjera
            break;
        case 'No producida':
            base = classes.chipTipoBorder;
            color = classes.chipNoProducida
            break;
        case 'Inédita':
            base = classes.chipTipoBorder;
            color = classes.chipInedita
            break;
        default:
            color = classes.chipDefault;
            break;
    }

    return classNames(
        base,
        color
    );
}