import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Manager, Target, Popper } from 'react-popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Notification from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Mail from '@material-ui/icons/Mail';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import { LanguageConsumer } from './../../language/LanguageContext';
import { withRouter } from 'react-router-dom';
import { S3Image } from 'aws-amplify-react';
import defaultAvatar from '../../../static/images/avatars/default.png';
//AHDA 25/04/2019
//Added dependency for ls 
import ls from 'local-storage';

//Notifi
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import Create from '@material-ui/icons/Create';
import FileUpload from '@material-ui/icons/FileUpload';
import Alarm from '@material-ui/icons/Alarm';
import Block from '@material-ui/icons/Block';
import Check from '@material-ui/icons/Check';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import NoteAdd from '@material-ui/icons/NoteAdd';
import LockOpen from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';

//Styles 
import '../../styles/components/Messages.scss';
import styles from './styles';

//Login 
import { logOut } from '../../services/cognito_service';
import { apiPOST, returnCurrentUserId, returnCurrentUser } from '../../services/service_base';
import { notifTypes } from '../../utils/notifTypes';


class UserMenu extends React.Component {
  //AHDA 25/04/2019
  //Added permissions
  state = {
    openMenu: null,
    notifications: [],
    user: undefined,
    //Permissions
    chkViewProfile: false
  };

  handleMenu = menu => {
    this.setState({
      openMenu: this.state.openMenu === menu ? null : menu,
    });
  };

  handleCloseToggle = () => {
    this.setState({ openMenu: null });
  };

  logout = () => {
    logOut();
  }

  goToProfile = () => {
    this.props.history.push('/profile');
  }


  handleUpdateItem = (item) => {
    const { notification_id } = item;
    let typeId = notifTypes.findIndex(t => t.notification_id === notification_id);

    apiPOST('/notifications/update', { idNotificacion: item.id_notificaciones }).then(
      response => {
        if (response.status === 200) {
          const notifications = this.state.notifications
          const index = notifications.findIndex(itm => itm.id_notificaciones === item.id_notificaciones);
          notifications.splice(index, 1);
          this.setState({ notifications: notifications }, () => {
            this.props.history.push(`/notifications?notif=${typeId}`);
          });
        } else {
          this.setState({ openError: true }, () => {
            this.props.history.push(`/notifications?notif=${typeId}`);
          });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false }, () => {
            this.props.history.push(`/notifications?notif=${typeId}`);
          });
        });
  }

  handleUpdatenotifications = () => {

    const request = {
      id_usuario: returnCurrentUserId(),
      portal: "acervo",
      stat: 0
    }

    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/notifications/get', request, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ notifications: response.data })
        } else {
          this.setState({ openError: true });
        }
      }).catch(
        error => {
          console.log(error)
        });
  }

  componentDidMount() {

    //AHDA 25/04/2019
    //Interval for getting profile permissions, temporal solution 
    let permissionInterval = setInterval(() => {
      let config = ls.get("user_permissions");

      if (config) {
        config = config.filter(x => x.menu_id === 5);
        if (config.length > 0) {
          config = config[0];
          let configPermission = JSON.parse(config.grant_configuration);

          this.setState({
            chkViewProfile: configPermission.chkViewProfile
          });
        } else {
          this.setState({
            chkViewProfile: false
          });
        }

        clearInterval(permissionInterval);
      }
    }, 2000);

    //Obteniendo notificaciones
    const request = {
      id_usuario: returnCurrentUserId(),
      portal: "acervo",
      stat: 0
    }

    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/notifications/get', request, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ notifications: response.data })
        } else {
          this.setState({ openError: true });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false });
        });


    setInterval(this.handleUpdatenotifications, 30000);

  }

  render() {
    const { classes } = this.props;
    const { openMenu } = this.state;
    const user = returnCurrentUser();

    const getNotification = (notification, idx) => {

      switch (notification.type) {
        case "newWorkRegister":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <LibraryAdd />
              </Avatar>
              <ListItemText primary={`Se ha registrado la obra '${notification.titulo_original}' en el portal de ACERVO`} secondary="Registro de obra" />
            </div>
          </MenuItem>
        case "workUpdate":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <Create />
              </Avatar>
              <ListItemText primary={`Se ha modificado la información de la obra '${notification.titulo_original}'`} secondary="Modificaciones a una obra" />
            </div>
          </MenuItem>
        case "evaluationAssessment":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <FileUpload />
              </Avatar>
              <ListItemText primary={`Se ha realizado la carga del dictamen para la obra '${notification.titulo_original}'`} secondary="Carga del dictamen de evaluación" />
            </div>
          </MenuItem>
        case "scriptsAccessRequest":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <Block />
              </Avatar>
              <ListItemText primary={`El usuario ${notification.productorAcervo}, está solicitando acceso a los libretos de la obra '${notification.titulo_original}'`} secondary="Solicitud de acceso a libretos." />
            </div>
          </MenuItem>
        case "scriptsAccess":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <Check />
              </Avatar>
              <ListItemText primary={`Se ha autorizado el acceso a los libretos al usuario ${notification.productorAcervo}, para la obra '${notification.titulo_original}'`} secondary="Acceso a libretos." />
            </div>
          </MenuItem>
        case "dueTimeProspect":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <Alarm />
              </Avatar>
              <ListItemText primary={`Queda 1 día para que la obra '${notification.titulo_original}' se libere y esté disponible para todos los usuarios.`} secondary=" Tiempo restante de una obra." />
            </div>
          </MenuItem>
        case "workAssigned":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <LocalLibrary />
              </Avatar>
              <ListItemText primary={`Se ha asignado como proyecto la obra '${notification.titulo_original}' al usuario ${notification.productorAcervo}`} secondary="Asignación de una obra como Proyecto" />
            </div>
          </MenuItem>
        case "workProspectUser":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <LocalLibrary />
              </Avatar>
              <ListItemText primary={`Se te ha asignado la obra '${notification.titulo_original}' para tu revisión`} secondary="Prospecto asignado para su revisión" />
            </div>
          </MenuItem>
        case "aditionalMaterialRegister":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <NoteAdd />
              </Avatar>
              <ListItemText primary={`Se ha cargado material adicional para la obra '${notification.titulo_original}'`} secondary="Carga de material adicional." />
            </div>
          </MenuItem>
        case "unlockWorkPropspect":
          //AHDA 27/05/2019: Changed message
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <LockOpen />
              </Avatar>
              <ListItemText primary={`La obra '${notification.titulo_original}' ha sido liberada por que expiró su tiempo`} secondary="Liberación de una obra marcada." />
            </div>
          </MenuItem>
        //AHDA 27/05/2019: Added case for userUnlockProspect
        case "unlockWorkPropspectUser":
          return <MenuItem onClick={() => this.handleUpdateItem(notification)} className={classes.textNotif} key={`notification_${idx}`}>
            <div className="messageInfo">
              <Avatar className="icon">
                <LockOpen />
              </Avatar>
              <ListItemText primary={`La obra '${notification.titulo_original}' ha sido liberada por el Productor  ${notification.productorAcervo}`} secondary="Liberación de una obra marcada." />
            </div>
          </MenuItem>
        default:
          return null;
      }
    }

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className={classes.userMenu}>
              <span className={classes.contactoData}>
                <a className={classes.enlaceBlanco} href="mailto:acervoliterario@televisaunivision.com"><Mail />
                  <Hidden xsDown>
                    <span>{lang.common.contact}</span>
                  </Hidden>
                </a>
              </span>
              {/* ----- Notification Dropdown Menu ----- */}
              <Manager>
                <Target>
                  <div
                    ref={node => {
                      this.target1 = node;
                    }}
                  >
                    <Tooltip title="Notificaciones">
                      <IconButton
                        aria-haspopup="true"
                        onClick={() => this.handleMenu('notification')}
                        color="inherit"
                      >
                        <Badge className={classes.badge} badgeContent={`${this.state.notifications.length}`} color="secondary">
                          <Notification />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Target>
                <Popper
                  placement="bottom-start"
                  eventsEnabled={openMenu === 'notification'}
                  className={classNames({ [classes.popperClose]: openMenu !== 'notification' }, classes.notifZindex)}
                >
                  <ClickAwayListener onClickAway={this.handleCloseToggle} >
                    <Grow in={openMenu === 'notification'} id="menu_notification" style={{ transformOrigin: '0 0 0' }}>
                      <Paper className={classes.paper}>
                        <MenuList role="menu" className={classes.notifMenu}>
                          {/* AHDA 27/95/2019: Added component for empty */}
                          {
                            this.state.notifications.length > 0 ?
                              this.state.notifications.map((notification, idx) =>
                              (

                                getNotification(notification, idx)
                              )) :
                              <MenuItem>
                                <div className="messageInfo">
                                  <ListItemText primary={"No cuentas con notificaciones"} secondary="" />
                                </div>
                              </MenuItem>
                          }
                        </MenuList>
                      </Paper>
                    </Grow>
                  </ClickAwayListener>
                </Popper>
              </Manager>
              {/* ----- End Notification Dropdown Menu ----- */}
              {/* ----- User Setting Dropdown Menu ----- */}
              <Manager>
                <Target>
                  <div
                    ref={node => {
                      this.target1 = node;
                    }}
                  >
                    <Tooltip title="Perfil">
                      <Button onClick={() => this.handleMenu('user-setting')}>
                        <Avatar
                          alt=""
                          src={defaultAvatar} 
                          // src={user ? "" : user.portrait ? "" : "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png"}
                          className={classNames(classes.borderUser, classes.unsetFlex)}>
                          {
                            !user ? "" : !user.portrait ? "" : <S3Image theme={{ photoImg: { width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' } }} imgKey={user.portrait} />
                          }
                        </Avatar>
                      </Button>
                    </Tooltip>
                  </div>
                </Target>
                <Popper
                  placement="bottom-start"
                  eventsEnabled={openMenu === 'user-setting'}
                  className={classNames({ [classes.popperClose]: openMenu !== 'user-setting' })}
                >
                  <ClickAwayListener onClickAway={this.handleCloseToggle}>
                    <Grow in={openMenu === 'user-setting'} id="user_settint" style={{ transformOrigin: '0 0 0' }}>
                      <Paper className={classes.paper}>
                        {/*AHDA 22/04/2019: Change in methods for onClick and in onTouchEnd*/}
                        <MenuList role="menu">
                          {/*AHDA 25/04/2019: added validation for permissions*/}
                          {
                            this.state.chkViewProfile &&
                            <MenuItem onClick={this.goToProfile} onTouchEnd={this.goToProfile}>
                              <ListItemIcon>
                                <Icon>account_circle</Icon>
                              </ListItemIcon>
                              {lang.common.profile}
                            </MenuItem>
                          }
                          {
                            this.state.chkViewProfile && <Divider />
                          }

                          <MenuItem onClick={this.logout} onTouchEnd={this.logout}>
                            <ListItemIcon>
                              <Icon>lock</Icon>
                            </ListItemIcon>
                            {lang.common.logout}
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    </Grow>
                  </ClickAwayListener>
                </Popper>
              </Manager>
              {/* ----- End User Setting Dropdown Menu ----- */}
            </div>
        }
      </LanguageConsumer>

    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UserMenu));
