import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import GoogleDocsViewer from 'react-google-docs-viewer';
import { getFile } from '../../../../services/storage';
import Loader from '../../../../components/Loader/Loader';
//import { S3Text } from 'aws-amplify-react';

const styles = () => ({
    container: {
        backgroundColor: "#e0e0e0"
    }
});

//Import with outer and not with dash 
class FileIndex extends React.Component {
    //TODO: get parameter in url
    state = {
        url: '',
        isLoading: true
    }
    componentDidMount() {
        let url = this.props.location.search.split('=');
        if(url.length > 0){ 
            let auxUrl = decodeURIComponent(url[1]); 
            getFile(auxUrl, 'public').then(response => {
                auxUrl = encodeURIComponent(response);  
                this.setState({url: auxUrl})
                setTimeout(()=>this.setState({isLoading: false}),
                2000)
                ; 
              }).catch(err => {
                this.setState({
                    url: "", 
                    isLoading: false
                });
            });
        }
        else{
            this.setState({
                url: "", 
                isLoading: false
            }); 
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                {
                    this.state.isLoading ? <Loader text="Cargando archivo" /> :
                        <GoogleDocsViewer width="100%" height="100%" fileUrl={`${this.state.url}`} />
                }
                {/*<S3Text textKey={this.state.url}/>*/}
            </div>

        );
    }
}


export default withRouter(withStyles(styles)(FileIndex)); 