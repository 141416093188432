import {
  Checkbox,
  InputLabel
} from '@material-ui/core';
import { FormControl, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Edit } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import iconPlay from '../../../static/images/iconplay.png';
import { getFileTitle } from '../../helpers/general';
import { LanguageConsumer } from '../../language/LanguageContext';
import MediaModalSlider from '../ProductDetail/MediaModalSlider';
import styles from './nuevaObra-jss';

class VideoPaper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      videos: [],
    };
  }

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleOpenModal = (newVideo) => {
    console.log(newVideo);
    // isanchez 08.05.2019 add else for preview
    if (!newVideo && this.state.videos.length > 0) {
      this.setState({ openModal: true });
    } else if (this.state.videos.length > 0) {
      this.setState({ openModal: true });
    }
  };

  //Life cycle
  componentDidMount() {
    // isanchez 08.05.2019 add attributes for preview and load multiple times the same video
    this.setState({
      videos: [
        {
          type: this.props.video.tipo_archivo,
          key: this.props.video.ruta,
          url: '',
          preview: this.props.video.file ? this.props.video.file.preview : '',
        },
      ],
    });
  }

  render() {
    const { onDelete, video, classes, onEdit, catalogName } = this.props;

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={3}>
                  <div
                    className={classes.videoContainer}
                    onClick={() => this.handleOpenModal(video.new)}
                  >
                    <img className={classes.videoImg} src={iconPlay} />
                  </div>

                  <div className={classes.alignCenter}>
                    <Typography variant="body1" className={classes.videoName}>
                      <small>
                        {video.ruta
                          ? getFileTitle(video.ruta)
                          : video.file
                            ? video.file.name
                            : ''}
                      </small>
                    </Typography>
                    <Typography variant="body2">
                      <small>
                        ({video.language ? video.language : video.idioma})
                      </small>
                    </Typography>
                    <Tooltip title={lang.common.delete}>
                      <IconButton
                        className={classes.button}
                        aria-label={lang.common.delete}
                        onClick={() => onDelete([video], 'video', 'new_mVideos')}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    {video.new&&<IconButton
                          className={classes.button}
                          aria-label={lang.common.edit}
                          onClick={() => onEdit(video, "video", "new_mVideos")}
                        >
                          <Edit className={classes.iconxs} />
                        </IconButton>}
                  </div>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={16}>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <InputLabel shrink>{lang.adminWork.material_description}</InputLabel>
                        <label className={classes.paddingTopLabel}>{video.description}</label>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth className={classes.formControlSpacing}>
                        <InputLabel shrink>{lang.adminWork.material_amount}</InputLabel>
                        <label className={classes.paddingTopLabel}>{video.amount}</label>
                      </FormControl>
                      <FormControl fullWidth className={classes.formControlSpacing}>
                        <InputLabel shrink>{catalogName}</InputLabel>
                        {video.video_config && <label className={classes.paddingTopLabel}>{video.video_config["duration"]}</label>}
                      </FormControl>
                    </Grid>
                    {video.video_config &&
                      <Grid item xs={12} sm={6}>
                        <div className={classNames(classes.flexboxInputs, classes.formControlSpacing)}>
                          <Typography
                            variant="body1"
                            component="span"
                            className={classes.checkboxLabel}
                          >
                            {lang.adminWork.material_audio}
                          </Typography>
                          <Checkbox
                            checked={video.video_config["audio"] || false}
                            disabled
                          />
                          <FormControl className={classes.flexGrow}>
                            <InputLabel shrink>{lang.adminWork.material_lang}</InputLabel>
                            <label className={classes.paddingTopLabel}>{video.video_config["audioLang"]}</label>
                          </FormControl>
                        </div>
                        <div className={classNames(classes.flexboxInputs, classes.formControlSpacing)}>
                          <Typography
                            variant="body1"
                            component="span"
                            className={classes.checkboxLabel}
                          >
                            {lang.adminWork.material_subtitle}
                          </Typography>
                          <Checkbox
                            checked={video.video_config["subtitle"] || false}
                            disabled
                          />
                          <FormControl className={classes.flexGrow}>
                            <InputLabel shrink>{lang.adminWork.material_lang}</InputLabel>
                            <label className={classes.paddingTopLabel}>{video.video_config["subLang"]}</label>
                          </FormControl>
                        </div>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>

              {this.state.videos.length > 0 ? (
                <Modal
                  aria-labelledby="media-modal"
                  aria-describedby=""
                  open={this.state.openModal}
                  onClose={this.handleCloseModal}
                  className="modalArrows dark"
                >
                  <MediaModalSlider
                    openModal={this.state.openModal}
                    handleCloseModal={this.handleCloseModal}
                    media={this.state.videos}
                  />
                </Modal>
              ) : null}
            </>
        }
      </LanguageConsumer>
    );
  }
}

VideoPaper.propTypes = {
  onDelete: PropTypes.func.isRequired,
  video: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoPaper);
