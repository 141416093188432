import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { S3Image } from 'aws-amplify-react';
import styles from './contact-jss';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import defaultAvatar from '../../../static/images/avatars/default.png';

//GEO 11/04/2019
//Se agrega tooltips
import Tooltip from '@material-ui/core/Tooltip';


//Iconos
// import AccountCircle from '@material-ui/icons/AccountCircle';
import Edit from '@material-ui/icons/Edit';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Block from '@material-ui/icons/Block';
// import Close from '@material-ui/icons/Close'; *****Usuario inactivo*****
import Check from '@material-ui/icons/Check';
import Email from '@material-ui/icons/Email';

const ITEM_HEIGHT = 48;

class ContactDetail extends React.Component {
  state = {
    anchorElOpt: null,
  };

  ////////////////////////// Options
  handleClickOpt = event => {
    this.setState({ anchorElOpt: event.currentTarget });
  };

  handleCloseOpt = () => {
    this.setState({ anchorElOpt: null });
  };

  handleBlockUser = (item) => {
    this.setState({ anchorElOpt: null });
    item.estatus = item.estatus === 1 ? 0 : 1;  
    this.props.blockUser(item); 
  }
  ////////////////////////// End options 
  //isanchez 30.04.19 
  addDefaultSrc(ev, item){
    //AHDA 05/06/2019: Added validation
    if(item.avatar){
      ev.target.src = ""
    }else{
      ev.target.src = defaultAvatar;
    }
  }

  render() {
    const {
      classes,
      edit,
      //favorite,
      showMobileDetail, 
      dataContact,
      item
    } = this.props;
    
    const { anchorElOpt } = this.state;

    if (dataContact.length === 0) {
      return (
        <main className={classNames(classes.content, showMobileDetail ? classes.detailPopup : '')}>
          <section className={classes.cover}>

          </section>
          <div>
          <Typography variant="body1">No hay usuarios disponibles</Typography>
          </div>
        </main>
      );
    }


    let disabledButton = true;
    //isanchez 27.03.19 start
    if(item !== undefined){
      if(item.estatus === 1 ){
        if(this.props.canBlock){
          disabledButton = true;
        }else{
          disabledButton = false;
        }
      }
  
      if(item.estatus === 0){
        if(this.props.canUnblock){
          disabledButton = true;
        }else{
          disabledButton = false;
        }
      }
    }
    //isanches 27.03.19 end
    
    //let data = dataContact.filter(x => x.id === itemSelected)[0];
    return (
      <main className={classNames(classes.content, showMobileDetail ? classes.detailPopup : '')}>
        {
          item ? 
          <React.Fragment>
            <section className={classes.cover}>
              <div className={classes.opt}>
              {/* GEO 11/04/2019
              Se agregan tooltips */}
                <Tooltip title="Editar">
                  <IconButton aria-label="Edit" disabled={!this.props.canEdit} onClick={() => edit(item)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Bloquear Usuario">
                  <IconButton
                    aria-label="More"
                    aria-owns={anchorElOpt ? 'long-menu' : null}
                    aria-haspopup="true"
                    className={classes.button}
                    onClick={this.handleClickOpt}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="long-menu"
                  anchorEl={anchorElOpt}
                  open={Boolean(anchorElOpt)}
                  onClose={this.handleCloseOpt}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: 200,
                    },
                  }}
                >

                  <MenuItem disabled={!disabledButton} onClick={() => this.handleBlockUser(item)}>
                    { item.estatus === 1 ? "Bloquear Usuario" : "Desbloquear Usuario" }
                  </MenuItem>


                </Menu>
              </div>
              {/* isanchez 30.04.19 inicio */}
              {/*AHDA 05/06/2019: Added validations for avatar*/}
              <Avatar 
                alt="Foto" 
                src={defaultAvatar} 
                // src={item.thumb[1]["100x100"] != undefined ? item.thumb[1]["100x100"] : item.avatar ? "" : "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png"} 
                className={classNames(classes.avatar, classes.unsetFlex)} 
                onError={(ev) => this.addDefaultSrc(ev, item)}
              >
              {
                  !item.thumb[0]["100x100"] ? <S3Image theme={{ photoImg: { width: '100px', height: '100px', objectFit: 'cover' } }} imgKey={item.avatar} /> : ""
              }
              </Avatar>
              {/* isanchez 30.04.19 fin */}              
              <Typography className={classes.userName} variant="display1">
                {`${item.nombre}`}
                <Typography variant="caption">
                  {item.unidad_negocio}
                </Typography>
              </Typography>
            </section>
            <div>
              {/* Usuarios internos */}
              <div>
                <List className={classes.contenedorDatos}>
                  <ListItem>
                    <Avatar className={classes.themeSecondaryIcon}>
                      <AssignmentInd />
                    </Avatar>
                    <ListItemText primary={item.nombre} secondary="Usuario" />
                  </ListItem>
                  <Divider variant="inset" />
                  <ListItem>
                    <Avatar className={classes.themeSecondaryIcon}>
                      <AssignmentInd />
                    </Avatar>
                    <ListItemText primary={item.apellidos} secondary="Apellido" />
                  </ListItem>
                  <Divider variant="inset" />
                  <ListItem>
                    <Avatar className={classes.themeSecondaryIcon}>
                      <Email />
                    </Avatar>
                    <ListItemText primary={item.usuario_cognito} secondary="Correo electrónico" />
                  </ListItem>
                  <Divider variant="inset" />
                  <ListItem>
                    <Avatar className={classes.themeSecondaryIcon}>
                      <BusinessCenter />
                    </Avatar>
                    <ListItemText primary={item.portal_profile} secondary="Perfil asignado en el portal" />
                  </ListItem>
                  <Divider variant="inset" />
                  <ListItem>
                    <Avatar className={classes.themeSecondaryIcon}>
                      {item.estatus === 1 ? <Check /> : <Block />}
                    </Avatar>
                    <ListItemText primary={item.estatus === 1 ? "Activo" : "Bloqueado"} secondary="Estatus" />
                  </ListItem>
                  <Divider variant="inset" />
                </List>
              </div>
            </div>
          </React.Fragment> : 
          <React.Fragment>
            <section className={classes.cover}>
            </section>
            <div className={classes.alignCard}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.w50}
                >
                  <Grid item xs={12}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant="body1" className={classes.textSelecciona}>Selecciona un usuario</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
          </React.Fragment>
        }
      </main>
    );
  }
}

ContactDetail.propTypes = {
  classes: PropTypes.object,
  showMobileDetail: PropTypes.bool,
  dataContact: PropTypes.array,
  edit: PropTypes.func,
  favorite: PropTypes.func,
  item: PropTypes.object, 
  blockUser: PropTypes.func,
  
};

ContactDetail.defaultProps = {
  item: undefined
}

export default withStyles(styles)(ContactDetail);
