import { awsapi } from '../../config/config'; 
import { API } from 'aws-amplify';
import ls from 'local-storage';

export const apiPOST = (path, body, avoidRefresh) => {
    
    //AHDA 16/05/2019: Added validation for refreshing activity
    if(!avoidRefresh){    
        ls.set("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }

    let myInit = {
        body: body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    return new Promise(
        function(resolve, reject){
            API.post(process.env.REACT_APP_aws_apigateway_name, path, myInit).then(response => {
                resolve(response); 
            }).catch(error => {
                reject(error); 
            });
        }
    )
}

export const apiGET = (path, params, avoidRefresh) => {
    
    //AHDA 16/05/2019: Added validation for refreshing activity
    if(!avoidRefresh){    
        ls.set("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }

    let myInit = { 
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        queryStringParameters: params
    }

    return new Promise(
        function(resolve, reject){
            API.get(process.env.REACT_APP_aws_apigateway_name, path, myInit).then(response => {
                resolve(response); 
            }).catch(error => {
                reject(error); 
            });
        }
    )
}

//AHDA 26/03/2019
//Method for consuming ElasticSearchApi
export const apiElasticSearch = (path, body, avoidRefresh) => {

    //AHDA 16/05/2019: Added validation for refreshing activity
    if(!avoidRefresh){    
        ls.set("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }

    let myInit = {
        body: body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    
    return new Promise(
        function(resolve, reject){
            API.post(process.env.REACT_APP_ELASTICNAME, path, myInit).then(response => {
                resolve(response); 
            }).catch(error => {
                reject(error); 
            });
        }
    )
}

export const fetchCall = (method,api, data, avoidRefresh) => {
    
    //AHDA 16/05/2019: Added validation for refreshing activity
    if(!avoidRefresh){    
        ls.set("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }

    return new Promise(
        function (resolve, reject) {
            var myHeaders = new Headers();

            var myInit = {
                method: method,
                headers: myHeaders,
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            };

            var myRequest = new Request(awsapi.endpoints[0].endpoint + api, myInit);

            fetch(myRequest)
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                }).catch((error) => {
                    reject(error);
                });
        }
    );
}

export const returnCurrentUserId = () =>{
    const user = ls.get("user_object"); 
    if(user !== null){
        return user.rdsUser.id_usuario; 
    }else{
        return 0; 
    }
}

export const returnCurrentUser = () => {
    let user = ls.get("user_object"); 
    if(user !== null){
        return user.rdsUser; 
    }else{
        return undefined; 
    }
}

export const updateCurrentUser = (newUser) => {
    let user = ls.get("user_object"); 
    if(user !== null){
        user.rdsUser.nombre = newUser.nombre; 
        user.rdsUser.apellidos = newUser.apellidos; 
        user.rdsUser.estatus = newUser.estatus; 
        user.rdsUser.id_rol = newUser.id_rol; 
        user.rdsUser.portrait = newUser.avatar;
        user.rdsUser.asigned_profile = newUser.asigned_profile;
        ls.set("user_object", user); 
        return true; 
    }else{
        return false; 
    }
}